import React, { Component } from "react";
import CoverageTable from "./CoverageTable";
import Loader from "./Loader";

import { getBacklogs, getRotations, getHolidays, getTeams } from "../api";
import { getCurrrentUTCDate, addDaysToDate, subtractDaysToDate } from "../momentTools";

class Coverage extends Component {
  state = {
    backlogs: {},
    teams: {},
    rotations: {},
    holidays: {},
    startDate: undefined,
    endDate: undefined,
    dates: [],
    numberOfDays: undefined,
    isTableLarge: false,
    showLoader: true,
  };

  componentDidMount() {
    let numberOfDays = 8;
    let startDate = getCurrrentUTCDate("YYYY-MM-DD");
    let startRotDate = subtractDaysToDate(startDate,1,"YYYY-MM-DD");
    let endDate = addDaysToDate(startDate, numberOfDays, "YYYY-MM-DD");
    let dates = new Array(numberOfDays)
    .fill(null)
    .map((key, index) => addDaysToDate(startDate, index, "YYYY-MM-DD"));

    Promise.all([ // 1.10.1 On Load of Coverage page get backlogs, teams , rotations and holidays and populate local state
      getBacklogs(),
      getTeams(),
      getRotations(startRotDate, endDate),
      getHolidays(startDate, endDate),
    ]).then(([backlogs, teams, rotations, holidays]) => {
      this.setState({
        backlogs: backlogs,
        teams: teams,
        rotations: rotations,
        holidays: holidays,
        startDate: startDate,
        endDate: endDate,
        dates: dates,
        numberOfDays: numberOfDays,
      });
      localStorage.setItem("rotations",JSON.stringify(rotations));
    })
    .catch(error => {
      console.log(error)
    })

    document.onkeyup = (e) => { // 1.10.2 Alt + T for large preview
      if (e.altKey && e.which === 84) {
        e.preventDefault();
        this.setState({ isTableLarge: !this.state.isTableLarge });
      }
    };
  }

  render() {
    let {
      startDate,
      endDate,
      dates,
      holidays,
      backlogs,
      rotations,
      teams,
    } = this.state;

    return (
      <div className="Coverage">
        <div className="row">
          <div className="col-sm-12">
            <h1>Backlog Coverage Report</h1>
            <h4 className="pt-5">
              For the next <b>{this.state.numberOfDays} days</b>
            </h4>
            <h6>
              <i>(Alt + T) for large preview</i>
            </h6>
            <div className="legend bg-yellow">OnCall</div>
            <div className="legend bg-orange">No Coverage</div>
            <div className="legend bg-teal">Open Office</div>
          </div>
        </div>
        <div className="row pt-5">
          {Object.keys(backlogs).length == 0 ? (
            <Loader showLoader={this.state.showLoader} />
          ) : (
            <CoverageTable
              startDate={startDate}
              endDate={endDate}
              dates={dates}
              holidays={holidays}
              backlogs={backlogs}
              teams={teams}
              rotations={rotations}
              showLarge={this.state.isTableLarge}
            />
          )}{/*1.10.3 show coverage table */}
        </div>
      </div>
    );
  }
}

export default Coverage;
