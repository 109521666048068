import React from 'react';
import Modal from 'react-bootstrap4-modal';

const MyModal = ({size='md', visible, modalBackdropClicked, header, body, btnPrimary, btnSecondary}) => {
  return(
    <Modal dialogClassName={'modal-' + size} visible={visible} onClickBackdrop={modalBackdropClicked}>
      <div className="modal-header">
        <h5 className="modal-title">{header}</h5>
      </div>
      <div className="modal-body col-md-12">
        {body}
      </div>
      {(btnSecondary || btnPrimary) && <div className="modal-footer">
        {btnSecondary && <button type="button" className={`btn ${btnSecondary.color}`} onClick={btnSecondary.onClick}>{btnSecondary.text}</button>}
        {btnPrimary && <button type="button" className={`btn ${btnPrimary.color} ${btnPrimary.disableButtons ? "disabled" : ""}`} onClick={btnPrimary.onClick}>{btnPrimary.text}</button>}
      </div>}
    </Modal>
  )
}

export default MyModal;
