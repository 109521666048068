import React, {useState} from 'react';
import './PeopleInBacklog.css'
import DeleteUser from './People/DeleteUser';

const PeopleInBacklog = (props) => {
  const {user, onSuccessDelete} = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const handleToggle = () => { setIsEditMode(!isEditMode) }

  var canPreview = (property, props) =>{
    const {ActiveUser, Roles} = props;

    if(!ActiveUser.role) return false;

    switch(property){
      case "editor": return Roles.BacklogRepresentative.includes(ActiveUser.role);
    }
  }

  return (
    <div className="col-sm-4" key={user.ID}>
      <div className="card user-info border-teal top-20 bg-teal">
        <div className="card-body">
          {canPreview("editor", props) && <button className="btn btn-danger btn-sm delete-button" onClick={handleToggle}>Remove</button>}
          <a className="btn btn-success btn-sm profile-button" href={`/profile/${user.ID}`}>Profile</a>
          <h5 className="card-title">
            {user.DisplayName} {user.LastName}<br />
            <span className="card-subtitle">{user.JobTitle}</span>
          </h5>
          <div className="card-text">
            <div className="d-inline-block float-left">
              <span><i className="material-icons">location_city</i>{(user.LocationName ? user.LocationName.split(" ").pop(-1) : "No Data")}</span>
            </div>
            <div className="d-inline-block float-right">
              <span><i className="material-icons">account_circle</i>{user.ManagerID}</span>
            </div>
          </div>
        </div>
      </div>

      { isEditMode &&
        <DeleteUser user={user} handleToggle={handleToggle} onSuccessDelete={onSuccessDelete} />
      }
    </div>
  )
}

export default PeopleInBacklog;
