import React, { Component } from "react";
import {
  getActiveRotations,
  getBacklogs,
  getAllUsers,
  getUserBacklogs,
  getHolidays,
  getLocations,
  getNextRotation,
  getTeams,
} from "../api";
import {
  addDaysToDate,
  getCurrrentUTCDate,
  getNextWeekday,
  getNumberDayofWeek,
  isBeforeDate,
  isBetweenDate,
} from "../momentTools";
import BacklogCard from "./Schedule/BacklogCard";
import Settings from "./Schedule/Settings";

class Schedule extends Component {
  state = {
    timeZone: "Etc/UTC",
    backlogs: undefined,
    users: undefined,
    teams: undefined,
    holidays: undefined,
    activeRotations: undefined,
  };
  backlogs = {};

  componentWillMount() { // 1.6 Schedule page
    Promise.all([  // 1.6.1 Onload of Schedule page , retrieve backlogs, active rotations,teams, holidays, locations
     
      getUserBacklogs(),
      getActiveRotations(),
      getTeams(),
      getHolidays(getCurrrentUTCDate("YYYY-MM")),
      getLocations(),
      getAllUsers()
    ])
      .then(([backlogs, activeRotations, teams, holidays, locations, users]) => {
        this.backlogs = backlogs; //1.6.2  searchInBacklogs 
        this.setState({ // 1.6.3 Populate state of schedule component
          backlogs: backlogs,
          activeRotations: activeRotations,
          teams: teams,
          holidays: holidays,
          locations,
          users: users
        });
      })
      .then(this.onCallStatus); // 1.6.4 Update OnCall Status of the backlogs
  }

  getBacklogHours = (backlogId) => {
    let startBacklog, endBacklog;
    let rotationDay = getCurrrentUTCDate("YYYYMMDD");

    if (!this.state.holidays[rotationDay.replace(/-/g,"")]) {
      startBacklog = this.state.backlogs[backlogId].WorkingHoursStart;
      endBacklog = this.state.backlogs[backlogId].WorkingHoursEnd;
      return { startBacklog, endBacklog };
    }

    let teams = this.state.teams[backlogId] || {};
    Object.keys(teams).forEach((key, value) => {
      let teamLocation = teams[key].LocationID.split("-")[0];
      if (!this.state.holidays[rotationDay.replace(/-/g,"")][teamLocation]) {
        if (!startBacklog || startBacklog > teams[key].WorkingHoursStart) {
          startBacklog = teams[key].WorkingHoursStart;
        }
        if (!endBacklog || endBacklog < teams[key].WorkingHoursEnd) {
          endBacklog = teams[key].WorkingHoursEnd;
        }
      }
    });

    if (!startBacklog || !endBacklog) {
      startBacklog = "00:00";
      endBacklog = "00:00";
    }

    return { startBacklog, endBacklog };
  };

  getNextTeams = (backlogId, rotationDay) => {
    let teams = this.state.teams[backlogId] || {};
    let firstTeamStartHour = undefined;
    let nextTeams = undefined;

    Object.keys(teams).forEach((key, value) => {
      let teamLocation = teams[key].LocationID.split("-")[0];
      if (
        !(
          this.state.holidays[rotationDay.replace(/-/g,"")] &&
          this.state.holidays[rotationDay.replace(/-/g,"")][teamLocation]
        ) &&
        (!firstTeamStartHour ||
          teams[key].WorkingHoursStart <= firstTeamStartHour)
      ) {
        firstTeamStartHour = firstTeamStartHour || teams[key].WorkingHoursStart;
        let team = { ...this.state.teams[backlogId][key] };
        nextTeams = { ...(nextTeams || {}), [key]: team };
      }
    });
    return nextTeams;
  };

  onCallStatus = () => {// 1.6.4 Update OnCall Status of the backlogs
    const { backlogs, activeRotations, teams } = this.state;
    if (!backlogs || !activeRotations || !teams) {
      return;
    }

    let noCoverageBacklogsID = [];//1.6.4-1 Iterate over backlogs
    Object.keys(backlogs).forEach((backlogId, index) => {
      let currentDate = getCurrrentUTCDate("YYYY-MM-DDTHH:mm");
      let rotationDay = getCurrrentUTCDate("YYYY-MM-DD");
      let { startBacklog, endBacklog } = this.getBacklogHours(backlogId);//1.6.4-2 Get backlog office start and end time
      let startBacklogDate =
        getCurrrentUTCDate("YYYY-MM-DD") + "T" + startBacklog; //1.6.4-3 Caclulate today's  start and end time for the backlog
      let endBacklogDate = getCurrrentUTCDate("YYYY-MM-DD") + "T" + endBacklog;

      if (isBeforeDate(endBacklogDate, startBacklogDate)) { //1.6.4-3 Add 1 day to endBacklogDate  
        endBacklogDate = addDaysToDate(endBacklogDate, 1, "YYYY-MM-DDTHH:mm");
      }

      if (
        getNumberDayofWeek(currentDate) % 6 !== 0 &&
        isBetweenDate(currentDate, startBacklogDate, endBacklogDate) //1.6.4-4 It is Open Office if on weekday and current time between backlogs open and close
      ) {
        backlogs[backlogId].status = "OPEN OFFICE";
        let activeTeams = {};
        Object.keys(teams[backlogId] || {}).forEach((teamId, index) => {//1.6.4-5 Iterate over teams to get active teams
          let activeTeam = teams[backlogId][teamId];
          let teamLocation = activeTeam.LocationID.split("-")[0];
          let startTeamDate =
            getCurrrentUTCDate("YYYY-MM-DD") +
            "T" +
            activeTeam.WorkingHoursStart;
          let endTeamDate =
            getCurrrentUTCDate("YYYY-MM-DD") + "T" + activeTeam.WorkingHoursEnd; //1.6.4-6 Calculate team start and end date

          if (
            !(
              this.state.holidays[rotationDay.replace(/-/g,"")] &&
              this.state.holidays[rotationDay.replace(/-/g,"")][teamLocation]
            ) &&
            isBetweenDate(currentDate, startTeamDate, endTeamDate)//1.6.4-7 Set active team if it is not a holiday and current date within team's open and close date
          ) {
            activeTeams[teamId] = activeTeam;
          }
        });

        backlogs[backlogId].teams = { ...activeTeams };// 1.6.4-8 Populate the active teams in open office
      } else if (activeRotations[backlogId]) { // 1.6.4-9 Backlog has active rotations , set ON-CALL and populate active Rotation object for backlog
        backlogs[backlogId].status = "ON-CALL";
        backlogs[backlogId].activeRotations = { ...activeRotations[backlogId] };
      } else {
        let nextTeams;
        backlogs[backlogId].status = "NO COVERAGE";//1.6.4-10 No active team or active rotation 

        if (currentDate <= endBacklogDate) {
          nextTeams = this.getNextTeams(backlogId, rotationDay);//1.6.4-11 get Next active team today if current datetime is less than backlog enddate
        } else {
          nextTeams = this.getNextTeams(
            backlogId,
            getNextWeekday("YYYY-MM-DD")
          );//1.6.4-11 get Next active team on next weekday
        }

        if (nextTeams) {
          backlogs[backlogId].nextTeams = nextTeams;// 1.6.4-12 attach next team object to backlog object
        }

        noCoverageBacklogsID.push(backlogs[backlogId].ID);//1.6.4-10 Populate No coverage array with this backlog id
      }

      this.setState({
        backlogs: { ...this.state.backlogs, [backlogId]: backlogs[backlogId] }, // 1.6.4-13 update state of backlogs
      });
    });

    if (noCoverageBacklogsID.length !== 0) {
      getNextRotation(noCoverageBacklogsID).then((res) => {// 1.6.4.14 Get next rotation for backlogs with no coverage,
        Object.keys(res).forEach((key, value) => {
          let backlog = this.state.backlogs[key];
          backlog.nextRotations = res[key];
          this.setState({
            backlogs: { ...this.state.backlogs, [key]: backlog },
          });
        });
      });
    }
  };

  filterBacklogsList = (radioName) => { //1.6.5-2 filter the backlog array by coverage type mand set updated backlog array in state
    let myBacklogs = {};
    let backlogsId = Object.keys(this.backlogs).filter((key, value) => {
      return this.backlogs[key].status === radioName;
    },this);
    backlogsId.forEach((id) => (myBacklogs[id] = this.backlogs[id]));
    this.setState({ backlogs: myBacklogs });
  };

  searchBacklogsList = (prop, query) => {//1.6.5-1 search backlog array by text and set state with search resultss
    let myBacklogs = {};
    let backlogsId = Object.keys(this.backlogs).filter((key, value) => {
      return (
        this.backlogs[key][prop].toLowerCase().search(query.toLowerCase()) !==
        -1
      );
    },this);
    backlogsId.forEach((id) => (myBacklogs[id] = this.backlogs[id]));
    this.setState({ backlogs: myBacklogs });
  };

  resetBacklogsList = () => {
    this.setState({ backlogs: this.backlogs });
  };

  getUserName = (id) => {
   let abc = this.state.users && this.state.users.find(item => item.ID == id)
   return abc && (abc.FirstName + ' '+ abc.LastName) || "-"
  }

  render() {
    const { backlogs, timeZone, locations, users } = this.state;
    return (
      <div className="Schedule">
        <h1 className="section-header">On-Call Master Schedule</h1>
        <div className="row pb-3">
          {/*  1.6.5 Settings widget for search and filter */}
          <Settings 
            searchBacklogsList={this.searchBacklogsList}
            filterBacklogsList={this.filterBacklogsList}
            resetBacklogsList={this.resetBacklogsList}
            setState={this.setState}
            setTimezone={(value) => this.setState({ timeZone: value })}
            getTimezone={() => this.state.timeZone}
            ActiveUser={this.props.ActiveUser}
          />
        </div>
        <div className="row py-2">
          <div className="BacklogListItem col-sm-12">
            <div className="row">
              {/*  1.6.6 Show backlogs list using Backlog card item */}
              {Object.keys(backlogs || {}).map((key, index) => ( 
                <BacklogCard
                  key={key}
                  timeZone={timeZone}
                  backlog={backlogs[key]}
                  ActiveUser={this.props.ActiveUser}
                  getUserName={this.getUserName}
                  Roles={this.props.Roles}
                  locations={locations}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedule;
