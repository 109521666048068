import React from "react";
import { getLocalizedTimefromDate, getTimezoneOffset } from "../../momentTools";

const RotationListItem = ({ rotation, timeZone, handleChange }) => {
  const rotationClone = { ...rotation };
  const rotationBadge =
    rotation.Type === "Weekday"
      ? "teal"
      : rotation.Type === "Weekend"
      ? "yellow"
      : "orange";

  // const updateRotationTime = (value, type) => {
  //   const localValue = value + getTimezoneOffset(timeZone);
  //   const utcValue = getLocalizedTimefromDate(
  //     localValue,
  //     "Etc/GMT",
  //     "YYYY-MM-DDTHH:mmZ"
  //   );
  //   rotationClone[type] = utcValue;
  //   handleChange(rotationClone);
  // };
  return (
    <li className="list-group-item rotation-item">
      <div className="row mb-2">
        <div className="col-sm-12">
          <b>{rotation.UserFullName}</b> ({rotation.UserID})
          <span className={"badge text-white ml-2 bg-" + rotationBadge}>
            {rotation.Type}
          </span>
          <span className={"badge text-white ml-2 bg-dark-grey"}>
            {rotation.Class}
          </span>
          <span className={"badge text-white ml-2 bg-dark-grey"}>
            {rotation.Chunk}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {/* {rotation.XLocationHoliday ? (
            <input
              type={"datetime-local"}
              onChange={(e) => updateRotationTime(e.target.value, "StartTime")}
              value={getLocalizedTimefromDate(
                rotation.StartTime,
                timeZone,
                "YYYY-MM-DDTHH:mm"
              )}
            />
          ) : (
            getLocalizedTimefromDate(
              rotation.StartTime,
              timeZone,
              "YYYY-MM-DDTHH:mm"
            )
          )} */}
          {getLocalizedTimefromDate(
            rotation.StartTime,
            timeZone,
            "YYYY-MM-DDTHH:mm"
          )}
        </div>
        <div className="col-sm-6">
          {/* {rotation.XLocationHoliday ? (
            <input
              type={"datetime-local"}
              onChange={(e) => updateRotationTime(e.target.value, "EndTime")}
              value={getLocalizedTimefromDate(
                rotation.EndTime,
                timeZone,
                "YYYY-MM-DDTHH:mm"
              )}
            />
          ) : (
            getLocalizedTimefromDate(
              rotation.EndTime,
              timeZone,
              "YYYY-MM-DDTHH:mm"
            )
          )} */}
          {getLocalizedTimefromDate(
            rotation.EndTime,
            timeZone,
            "YYYY-MM-DDTHH:mm"
          )}
        </div>
      </div>
    </li>
  );
};

export default RotationListItem;
