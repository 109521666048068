import React, { Component } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  addDaysToDate,
  addHoursToDate,
  addHoursToDateTime,
  getTimeDiff,
  formatDate,
  getLocalizedTimefromDate,
  getNumberDayofWeek,
  subtractDaysToDate,
} from "../..//momentTools";
import { getHolidays, getRotation, getTeamsInBacklog } from "../../api";
import "./Calendar.css";
import ShowOnlyDate from "./ShowOnlyDate";
import MyModal from "../Modal";
import ChooseEngineer from "./ChooseEngineer";

class Calendar extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedRotations: {},
    submittedRotationsRaw: [],
    removedRotations: {},
    submittedRotations: {},
    teams: {},
    holidays: {},
    currentDate: new Date(),
    serviceTeamManagers: {},
    showOnlyDate: false,
    selectedDate: null,
  };

  RotationTypes = {
    Holiday: "Holiday",
    Weekend: "Weekend",
    Weekday: "Weekday",
  };

  componentDidMount() {
    let currentDate = formatDate(this.state.currentDate, "YYYY-MM");
    let backlogId = this.props.rotationBacklog.ID;

    getRotation(backlogId, currentDate).then((res) => {
      const modifiedRotationsWithUniqueID = Object.entries(res)
        .map(([key, obj]) => {
          return {
            [key]: Object.entries(obj).reduce((prev, curr) => {
              prev[[curr[1].ID]] = curr[1];
              return prev;
            }, {}),
          };
        })
        .reduce((prev, curr) => {
          Object.entries(curr).map(([key, obj]) => {
            prev[key] = obj;
          });
          return prev;
        }, {});
      this.setState({
        submittedRotations: modifiedRotationsWithUniqueID,
        submittedRotationsRaw: modifiedRotationsWithUniqueID,
      });
    });
    getTeamsInBacklog(backlogId).then((res) => {
      const serviceTeamManagers = Object.values(res)
        .filter((team) => team.IsAServiceTeam)

        .reduce(
          (acc, team) => ({
            ...acc,
            [team.ID]: team.ManagerID,
          }),
          {}
        );
      //  this.setState({teams: res});
      this.setState({ serviceTeamManagers });
    });
    getHolidays(currentDate).then((res) => this.setState({ holidays: res }));
    getHolidays(currentDate).then((res) => this.setState({ holidays: res }));
    //this.props.onSubmitRotationsUpdate(this.state.submittedRotations);
  }

  onSubmittedRotationsUpdate = (submittedRotations) => {
    this.setState({ submittedRotations: submittedRotations });
  };

  getBacklogHours = (rotationDay) => {
    let startBacklog, endBacklog;
    if (!this.state.holidays[rotationDay.replace(/-/g, "")]) {
      startBacklog = this.props.rotationBacklog.WorkingHoursStart;
      endBacklog = this.props.rotationBacklog.WorkingHoursEnd;
      return { startBacklog, endBacklog };
    }
    Object.keys(this.state.teams).map((key) => {
      let teamLocation = this.state.teams[key].LocationID.split("-")[0];
      if (!this.state.holidays[rotationDay.replace(/-/g, "")][teamLocation]) {
        if (
          !startBacklog ||
          startBacklog > this.state.teams[key].WorkingHoursStart
        ) {
          startBacklog = this.state.teams[key].WorkingHoursStart;
        }
        if (!endBacklog || endBacklog < this.state.teams[key].WorkingHoursEnd) {
          endBacklog = this.state.teams[key].WorkingHoursEnd;
        }
      }
    });
    if (!startBacklog || !endBacklog) {
      startBacklog = this.props.rotationBacklog.WorkingHoursStart;
      endBacklog = this.props.rotationBacklog.WorkingHoursStart;
    }
    return { startBacklog, endBacklog };
  };

  getRotationType = (rotationDay, userLocation) => {
    let currentHolidays =
      this.state.holidays[rotationDay.replace(/-/g, "")] || {};
    if (currentHolidays[userLocation]) {
      return this.RotationTypes.Holiday;
    } else if (getNumberDayofWeek(rotationDay) % 6 === 0) {
      return this.RotationTypes.Weekend;
    } else {
      return this.RotationTypes.Weekday;
    }
  };

  getRotationHours = (
    rotationDay,
    userLocation,
    rotationType,
    rotationChunk
  ) => {
    let backlog = this.props.rotationBacklog;
    let nextDay = addDaysToDate(rotationDay, 1, "YYYY-MM-DD");
    let { endBacklog } = this.getBacklogHours(rotationDay);
    let { startBacklog } = this.getBacklogHours(nextDay);
    let startShift, endShift;

    if (userLocation === "PL" || userLocation === "NL") {
      if (rotationType === this.RotationTypes.Weekday) {
        startShift = `${rotationDay}T15:00+00:00`;
        endShift = `${rotationDay}T20:00+00:00`;
      } else if (
        rotationType === this.RotationTypes.Weekend ||
        rotationType === this.RotationTypes.Holiday
      ) {
        startShift = `${rotationDay}T07:00+00:00`;
        endShift = `${rotationDay}T20:00+00:00`;
      }
    } else if (
      userLocation === "IN" &&
      this.props.rotationBacklog.IndiaWeekdayOnCallHoursStart &&
      this.props.rotationBacklog.IndiaWeekdayOnCallHoursEnd
    ) {
      let weekdayOnCallStart =
        this.props.rotationBacklog.IndiaWeekdayOnCallHoursStart;
      let weekdayOnCallEnd =
        this.props.rotationBacklog.IndiaWeekdayOnCallHoursEnd;
      let weekdayOnCallhrs = getTimeDiff(
        weekdayOnCallEnd,
        weekdayOnCallStart,
        "HH:mm"
      );
      // For valid entries of weekdayOnCallStart and weekdayOnCallEnd and weekdayOnCallhrs is zero then it is 24 hours support
      if (weekdayOnCallhrs === 0 && weekdayOnCallStart && weekdayOnCallEnd) {
        weekdayOnCallhrs = 24;
      }

      let holidayOnCallStart =
        this.props.rotationBacklog.IndiaHolidayOnCallHoursStart;
      let holidayOnCallEnd =
        this.props.rotationBacklog.IndiaHolidayOnCallHoursEnd;

      let holidayOnCallhrs = getTimeDiff(
        holidayOnCallEnd,
        holidayOnCallStart,
        "HH:mm"
      );
      // For valid entries of weekdayOnCallStart and weekdayOnCallEnd and weekdayOnCallhrs is zero then it is 24 hours support
      if (holidayOnCallhrs === 0 && holidayOnCallStart && holidayOnCallEnd) {
        holidayOnCallhrs = 24;
      }
      // if (rotationType === this.RotationTypes.Weekday && endBacklog < startBacklog) {
      //   startShift = addDaysToDate(rotationDay, 1, "YYYY-MM-DD") + "T" +  endBacklog + "+00:00";
      //   endShift =  addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +  "T" + startBacklog + "+00:00";
      // } else
      if (rotationType === this.RotationTypes.Weekday) {
        startShift = rotationDay + "T" + weekdayOnCallStart + "+00:00";
        //endShift = addDaysToDate(rotationDay, 1, "YYYY-MM-DD") + "T" + startBacklog + "+00:00";
        endShift =
          addHoursToDateTime(
            rotationDay + "T" + weekdayOnCallStart,
            weekdayOnCallhrs,
            "YYYY-MM-DDTHH:mm"
          ) + "+00:00";
      } // If holidays
      else if (
        rotationType === this.RotationTypes.Weekend ||
        rotationType === this.RotationTypes.Holiday
      ) {
        if (!rotationChunk || rotationChunk === "1/1") {
          startShift = rotationDay + "T" + holidayOnCallStart + "+00:00";
          endShift =
            addHoursToDateTime(
              rotationDay + "T" + holidayOnCallStart,
              holidayOnCallhrs,
              "YYYY-MM-DDTHH:mm"
            ) + "+00:00";
        } else if (rotationChunk === "1/2") {
          startShift = rotationDay + "T" + backlog.WorkingHoursStart + "+00:00";
          endShift =
            rotationDay +
            "T" +
            addHoursToDate(backlog.WorkingHoursStart, 12, "HH:mm") +
            "+00:00";
        } else if (rotationChunk === "2/2") {
          startShift =
            rotationDay +
            "T" +
            addHoursToDate(backlog.WorkingHoursStart, 12, "HH:mm") +
            "+00:00";
          endShift =
            addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
            "T" +
            backlog.WorkingHoursStart +
            "+00:00";
        }
      }
    } else {
      if (
        rotationType === this.RotationTypes.Weekday &&
        endBacklog < startBacklog
      ) {
        startShift =
          addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
          "T" +
          endBacklog +
          "+00:00";
        endShift =
          addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
          "T" +
          startBacklog +
          "+00:00";
      } else if (rotationType === this.RotationTypes.Weekday) {
        startShift = rotationDay + "T" + endBacklog + "+00:00";
        endShift =
          addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
          "T" +
          startBacklog +
          "+00:00";
      } // If holidays
      else if (
        rotationType === this.RotationTypes.Weekend ||
        rotationType === this.RotationTypes.Holiday
      ) {
        if (!rotationChunk || rotationChunk === "1/1") {
          startShift = rotationDay + "T" + backlog.WorkingHoursStart + "+00:00";
          endShift =
            addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
            "T" +
            backlog.WorkingHoursStart +
            "+00:00";
        } else if (rotationChunk === "1/2") {
          startShift = rotationDay + "T" + backlog.WorkingHoursStart + "+00:00";
          endShift =
            rotationDay +
            "T" +
            addHoursToDate(backlog.WorkingHoursStart, 12, "HH:mm") +
            "+00:00";
        } else if (rotationChunk === "2/2") {
          startShift =
            rotationDay +
            "T" +
            addHoursToDate(backlog.WorkingHoursStart, 12, "HH:mm") +
            "+00:00";
          endShift =
            addDaysToDate(rotationDay, 1, "YYYY-MM-DD") +
            "T" +
            backlog.WorkingHoursStart +
            "+00:00";
        }
      }
    }

    return { startShift, endShift };
  };

  getRotationDetails = (rotationDay, rotationUser) => {
    //debugger;
    let userFullName = rotationUser.UserFullName || rotationUser.FirstName + " " + rotationUser.LastName;
    let userLocation = rotationUser.UserLocation || rotationUser.Location.split("-")[0];
    let managerId = rotationUser.ManagerID;
    let rotationType = this.getRotationType(rotationDay, userLocation);
    let rotationClass = this.props.rotationClass;
    let rotationChunk =
      userLocation === "PL" || userLocation === "NL"
        ? undefined
        : getNumberDayofWeek(rotationDay) % 6 === 0 ||
          rotationType === this.RotationTypes.Holiday
        ? rotationUser.Chunk || this.props.rotationChunk
        : undefined;
    let { startShift, endShift } = this.getRotationHours(
      rotationDay,
      userLocation,
      rotationType,
      rotationChunk
    );
    let holidayId =
      rotationType === this.RotationTypes.Holiday
        ? this.state.holidays[rotationDay.replace(/-/g, "")][userLocation].ID
        : undefined;
    let isHoliday = this.state.holidays[rotationDay.replace(/-/g, "")] != null;
    if (rotationChunk === "1/2" || rotationChunk === "2/2") {
      isHoliday = false;
    }
    if (userLocation === "PL" || userLocation === "NL") isHoliday = false;
    return {
      BacklogID: this.props.rotationBacklog.ID,
      UserID: rotationUser.UserID || rotationUser.ID,
      UserFullName: userFullName,
      UserLocation: userLocation,
      Day: rotationDay,
      StartTime: startShift,
      EndTime: endShift,
      Type: rotationType,
      HolidayID: holidayId,
      XLocationHoliday: isHoliday,
      ManagerID: managerId,
      Class: rotationClass,
      Chunk: rotationChunk,
    };
  };

  isUserEligible = (rotationDay, rotationUserID) => {
    const { serviceTeamManagers } = this.state;
    const { rotationUser, backlogUsers } = this.props;

    let userLocation = this.props.rotationUser.Location.split("-")[0];

    let nextDay = addDaysToDate(rotationDay, 1, "YYYY-MM-DD");
    let previousDay = subtractDaysToDate(rotationDay, 1, "YYYY-MM-DD");
    let { startBacklog, endBacklog } = this.getBacklogHours(rotationDay);

    let weekendErrorMessage =
      "You can select only one day of this weekend for the selected engineer, due to the local work regulations in the EU.";
    let weekdayErrorMessage =
      "Selected rotation for this engineer is shorter than regular backlog working hours.";

    let isSubmitted = (day = rotationDay) => {
      return !!(
        this.state.submittedRotations[day] &&
        this.state.submittedRotations[day][rotationUserID]
      );
    };

    let isSelected = (day = rotationDay) => {
      return !!(
        this.state.selectedRotations[day] &&
        this.state.selectedRotations[day][rotationUserID]
      );
    };

    let isRemoved = (day = rotationDay) => {
      return !!(
        this.state.removedRotations[day] &&
        this.state.removedRotations[day][rotationUserID]
      );
    };

    let isHoliday = (day = rotationDay, location = userLocation) => {
      return (
        this.state.holidays[day.replace(/-/g, "")] &&
        this.state.holidays[day.replace(/-/g, "")][location]
      );
    };

    let isDayEmpty = (day = rotationDay) => {
      return !isSelected(day) && !isSubmitted(day);
    };

    let isOtherDayEmpty = (day = rotationDay) => {
      let otherDay =
        getNumberDayofWeek(day) === 6
          ? nextDay
          : getNumberDayofWeek(day) === 0
          ? previousDay
          : undefined;
      if (isRemoved(otherDay)) {
        return true;
      }
      return isDayEmpty(otherDay);
    };
    if (rotationUser.ID === "MAJAV") return true;
    if (userLocation !== "PL" && userLocation !== "NL") {
      return true;
    } else {
      const teamId =
        rotationUser.teamId ||
        (backlogUsers[rotationUser.ManagerID] || {}).teamID;
      if (teamId && teamId in serviceTeamManagers) return true;
    }

    if (!isDayEmpty()) {
      if (getNumberDayofWeek(rotationDay) % 6 === 0 && isOtherDayEmpty()) {
        return true;
      }
      if (getNumberDayofWeek(rotationDay) % 6 !== 0) {
        return true;
      }
      toast.error(weekendErrorMessage, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    if (!(isDayEmpty() && isOtherDayEmpty())) {
      toast.error(weekendErrorMessage, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    /* if (
       getNumberDayofWeek(rotationDay) % 6 !== 0 &&
       endBacklog >= "20:00+00:00"
     ) {
       if (isHoliday()) {
         return true;
       }
 
       toast.error(weekdayErrorMessage, { position: toast.POSITION.TOP_RIGHT });
       return false;
     }
 */
    return true;
  };

  areRotationsUSCompliant () {
    let selectedRotationsAll = this.props.rotations || {};

    let isNonCompliant = false;
    let usOnCallHrs = 0;
    Object.keys(selectedRotationsAll).map((rotationDayMainKey, item) => {
      if (!isNonCompliant) {
        Object.keys(selectedRotationsAll[rotationDayMainKey]).map(
          (rotationUserCurrent, index) => {
            if (!isNonCompliant) {
              let rotationUser =
                selectedRotationsAll[rotationDayMainKey][rotationUserCurrent];
              let location = rotationUser.UserLocation;
              let selectedUserId = rotationUser.UserID;

              if (location === "US") {
                let complianceUSErrorMessageDay = `You can select upto 24 hours only per day for the user ${selectedUserId}, due to the local work regulations in US.`;
                usOnCallHrs =
                  Math.abs(
                    new Date(rotationUser.EndTime) -
                      new Date(rotationUser.StartTime)
                  ) /
                    3600000;
                if (usOnCallHrs > 24 || usOnCallHrs === 0) {
                  toast.error(complianceUSErrorMessageDay, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  isNonCompliant = true;
                  return;
                }
              }
            }
          }
        );
      }
    });
    if (isNonCompliant) {
      return;
    }
    return true;
  }

  isRotationEngIndiaOrUSComplaint(
    rotationDay,
    currentRotations,
    rotationUser,
    allowedHours
  ) {
    //debugger;
    let compliantObj = { flag: true, rtUserId: rotationUser.ID };
    let rotationUserLocation = String(
      rotationUser.Location || rotationUser.UserLocation
    )
      .toLocaleLowerCase()
      .split("-")[0];
    if (rotationUserLocation === "in") {
      let submittedRotationHours = 0;
      let selectedRotationHours = 0;
      let removedRotationHours = 0;
      let currentRotationHours = 0;
      let submittedRotations = this.state.submittedRotations || {};
      let selectedRotations = this.state.selectedRotations || {};
      let removedRotations = this.state.removedRotations || {};
      let rotationWeekStartDate = rotationDay;
      let rotationWeekEndDate = rotationDay;
      let selectedUserId = rotationUser.UserID;
      // error will be only shown if India is location, condition based from ShowOnlyDate.js
      let complianceErrorMessage = `You can select only ${allowedHours} hours per week for the selected engineer ${selectedUserId}, due to the local work regulations in ${
        rotationUserLocation
      }`;
      // let complianceIndiaErrorMessageDay = `You need to select at atleast ${
      //   allowedHours / 2
      // } and only ${allowedHours} hours per day for the user ${selectedUserId}, due to the local work regulations in India.`;
      // let complianceUSErrorMessageDay = `You can select upto 24 hours only per day for the user ${selectedUserId}, due to the local work regulations in US.`;

      if (currentRotations) {
        //Initialize selectedRotationHours with Current selected Rotation Hours
        Object.entries(currentRotations || {}).map(([key, val]) => {
          if (val.UserID === rotationUser.UserID) {
            currentRotationHours =
              currentRotationHours +
              Math.abs(new Date(val.EndTime) - new Date(val.StartTime)) /
                3600000;
          }
        });
        if (currentRotationHours > allowedHours) {
          toast.dismiss();
          toast.error(complianceErrorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return (compliantObj = { flag: false, rtUserId: rotationUser.ID });
        }
      }

      while (getNumberDayofWeek(rotationWeekStartDate) != 0) {
        rotationWeekStartDate = subtractDaysToDate(
          rotationWeekStartDate,
          1,
          "YYYY-MM-DD"
        );
      }
      rotationWeekEndDate = addDaysToDate(
        rotationWeekStartDate,
        6,
        "YYYY-MM-DD"
      );

      // Iterate Removed Rotations to cal removed Rotation Hours
      Object.keys(removedRotations).map((rotationDayKey, item) => {
        if (
          rotationDayKey >= rotationWeekStartDate &&
          rotationDayKey <= rotationWeekEndDate
        ) {
          Object.keys(removedRotations[rotationDayKey]).map(
            (rotationUserID, index) => {
              let rotationUserCurrent =
                removedRotations[rotationDayKey][rotationUserID];
              if (rotationUserCurrent.UserID === rotationUser.UserID) {
                removedRotationHours =
                  removedRotationHours +
                  Math.abs(
                    new Date(rotationUserCurrent.EndTime) -
                      new Date(rotationUserCurrent.StartTime)
                  ) /
                    3600000;
              }
            }
          );
        }
      });

      // Iterate CurrentRotations to calc currenRotation Hours
      let isNonCompliant = false;
      // Object.keys(selectedRotations).map((rotationDayKey, item) => {
      //   if (
      //     !isNonCompliant &&
      //     rotationDayKey >= rotationWeekStartDate &&
      //     rotationDayKey <= rotationWeekEndDate
      //   ) {
      //     Object.keys(selectedRotations[rotationDayKey]).map(
      //       (rotationUserID, index) => {
      //         let rotationUserCurrent =
      //           selectedRotations[rotationDayKey][rotationUserID];
      //         if (rotationUserCurrent.UserID === rotationUser.ID) {
      //           let selectedRotationHoursCur =
      //             Math.abs(
      //               new Date(
      //                 rotationUserCurrent.End || rotationUserCurrent.EndTime
      //               ) -
      //                 new Date(
      //                   rotationUserCurrent.Start || rotationUserCurrent.StartTime
      //                 )
      //             ) / 3600000;
      //           if (
      //             !(
      //               (selectedRotationHoursCur === 12 &&
      //                 (rotationUserCurrent.Chunk === "1/2" ||
      //                   rotationUserCurrent.Chunk === "2/2")) ||
      //               selectedRotationHoursCur >= allowedHours / 2 ||
      //               selectedRotationHoursCur === allowedHours
      //             )
      //           ) {
      //             toast.error(complianceErrorMessageDay, {
      //               position: toast.POSITION.TOP_RIGHT,
      //             });
      //             compliantObj = { flag: false, rtUserId: rotationUser.ID };
      //             isNonCompliant = true;
      //             return;
      //           }
      //           selectedRotationHours =
      //             selectedRotationHours + selectedRotationHoursCur;
      //           if (
      //             submittedRotationHours +
      //               selectedRotationHours -
      //               removedRotationHours >
      //             allowedHours
      //           ) {
      //             toast.warn(complianceErrorMessage, {
      //               position: toast.POSITION.TOP_RIGHT,
      //             });
      //             compliantObj = { flag: false, rtUserId: rotationUser.ID };
      //             //toast.error(complianceErrorMessage, { position: toast.POSITION.TOP_RIGHT });
      //             isNonCompliant = true;
      //             return;
      //           }
      //         }
      //       }
      //     );
      //     // if (isNonCompliant) {
      //     //   return;
      //     // }
      //   }
      // });

      // if (isNonCompliant) {
      //   //warning only so return true for now- Errors are thrown in "areUserRotationsValid" method on "Submit Rotations" in Rotations.js
      //   return true;
      // }

      // Iterate SubmittedRotations to calc submittedRotation Hours
      Object.keys(submittedRotations).map((rotationDayKey, item) => {
        if (
          !isNonCompliant &&
          new Date(rotationDayKey) >= new Date(rotationWeekStartDate) &&
          new Date(rotationDayKey) <= new Date(rotationWeekEndDate)
        ) {
          Object.keys(submittedRotations[rotationDayKey]).map(
            (rotationUserID, index) => {
              let rotationUserCurrent =
                submittedRotations[rotationDayKey][rotationUserID];
              if (
                rotationUserCurrent.UserID === rotationUser.UserID &&
                Object.keys(currentRotations).indexOf(rotationUserID) < 0
              ) {
                submittedRotationHours =
                  submittedRotationHours +
                  Math.abs(
                    new Date(rotationUserCurrent.EndTime) -
                      new Date(rotationUserCurrent.StartTime)
                  ) /
                    3600000;
                //compute and throw error message if selected engineer is allocated for more than 14 hours
                if (
                  submittedRotationHours + currentRotationHours >
                  allowedHours
                ) {
                  //toast.error(complianceErrorMessage, { position: toast.POSITION.TOP_RIGHT });
                  toast.dismiss();
                  toast.error(complianceErrorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  compliantObj = { flag: false, rtUserId: rotationUser.ID };
                  isNonCompliant = true;
                  return;
                }
              }
            }
          );
          // if (isNonCompliant) {
          //   return;
          // }
        }
      });
    }
    return compliantObj;
  }

  isAlreadySubmitted = (
    rotationDay,
    { ID: rotationUserID, UserFullName: rtFullName }
  ) => {
    let submittedRotations =
      this.state.submittedRotationsRaw[rotationDay] || {};
    let removedRotations = this.state.removedRotations || {};
    let removedRotationsDay = removedRotations[rotationDay] || {};
    if (!submittedRotations[rotationUserID]) {
      return false;
    }
    if (!removedRotationsDay[rotationUserID]) {
      removedRotationsDay[rotationUserID] = {
        ...submittedRotations[rotationUserID],
        managerId: this.props.rotationUser.ManagerID,
        UserFullName: rtFullName,
      };
      removedRotations[rotationDay] = removedRotationsDay;
    } else {
      delete removedRotationsDay[rotationUserID];
      if (Object.keys(removedRotationsDay).length === 0) {
        delete removedRotations[rotationDay];
      }
    }
    this.setState(
      {
        removedRotations: { ...removedRotations },
      },
      () => {
        this.props.onRemovedRotationsUpdate(this.state.removedRotations);
      }
    );
    return true;
  };

  updateSelectedRotations = (selectedDate, selectedRotations) => {
    let currentRotations = this.state.selectedRotations;
    currentRotations[selectedDate] = selectedRotations;
    this.setState(
      {
        selectedRotations: { ...currentRotations },
      },
      () => {
        this.props.onSelectedRotationsUpdate(this.state.selectedRotations);
      }
    );
  };

  onDayClick = (day) => {
    this.setState({ selectedDate: day, showOnlyDate: true });
  };

  renderDay = (day) => {
    let rotationDay = formatDate(day, "YYYY-MM-DD");
    let currentRotations = this.state.selectedRotations[rotationDay] || {};
    let currentHolidays =
      this.state.holidays[formatDate(day, "YYYYMMDD")] || {};
    let submittedRotations = this.state.submittedRotations[rotationDay] || {};
    let removedRotations = this.state.removedRotations[rotationDay] || {};

    return (
      <div className={`cellStyle ${this.props.readOnly ? "readOnly" : ""}`}>
        <div className="dateStyle">
          {day.getDate()}
          {Object.keys(currentHolidays).map((item, key) => {
            return (
              <span key={item} className="holidayStyle">
                <span
                  className={"badge calendar text-white bg-orange"}
                  data-tip
                  data-for={`holiday-${currentHolidays[item].ID}`}
                >
                  {currentHolidays[item].Location}
                </span>
                <ReactTooltip
                  id={`holiday-${currentHolidays[item].ID}`}
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <span>
                    {currentHolidays[item].Location}: --
                    {currentHolidays[item].Name}
                  </span>
                </ReactTooltip>
              </span>
            );
          })}
        </div>
        <div className="rotationStyle" >
       
          
          {Object.keys(submittedRotations).map((item, index) => {
            return (
             <div className={"m-0"} key={index}>{(index) < 2? submittedRotations[item].UserID.toLowerCase():"" }</div>    
             )
            })
          }
          
          {Object.keys(submittedRotations).length > 2 && (
            <>
              <span
                className={"badge calendar text-white bg-dark-grey"}
                title="Assigned Engineers"
              >
                {"+ "+(Object.keys(submittedRotations).length-2)}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  onMonthChange = (element) => {
    let currentDate = formatDate(element, "YYYY-MM");
    getRotation(this.props.rotationBacklog.ID, currentDate).then((res) => {
      const modifiedRotationsWithUniqueID = Object.entries(res)
        .map(([key, obj]) => {
          return {
            [key]: Object.entries(obj).reduce((prev, curr) => {
              prev[[curr[1].ID]] = curr[1];
              return prev;
            }, {}),
          };
        })
        .reduce((prev, curr) => {
          Object.entries(curr).map(([key, obj]) => {
            prev[key] = obj;
          });
          return prev;
        }, {});
      this.setState({
        submittedRotations: modifiedRotationsWithUniqueID,
        submittedRotationsRaw: modifiedRotationsWithUniqueID,
      });
    });
    //this.props.onSubmittedRotationsUpdate(this.state.submittedRotations);
    getHolidays(currentDate).then((res) => this.setState({ holidays: res }));
  };

  updateShowOnlyDate = () => {
    this.setState({ showOnlyDate: false });
  };

  render() {
    const selectedDate = this.state.selectedDate;
    const { readOnly, previewOnly } = this.props;
    return (
      <div className="rotationCalendarSec">
        <ToastContainer hideProgressBar={true} />
        <DayPicker
          clasName="pb-5"
          dateFormat="dd/MM/yyyy"
          firstDayOfWeek={0}
          month={this.state.currentDate}
          onMonthChange={this.onMonthChange}
          renderDay={this.renderDay}
          onDayClick={this.onDayClick}
          modifiers={{ weekends: { daysOfWeek: [0, 6] } }}
        />
        <div>
          <p>
            <small>
              Note: Grey color text/number on the above calendar day represents the Number of Assigned Engineers for that date 
            </small>
          </p>
        </div>
        {this.state.showOnlyDate && (
          <MyModal
            header={
              <div>
                {readOnly ? "Rotation Engineers on" : "Choose Engineer on"}
                <b>
                  {" " +
                    getLocalizedTimefromDate(
                      selectedDate,
                      this.props.timeZone,
                      "DD-MM-YYYY"
                    )}
                </b>
                
                  <span className="rt-modal-close">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={this.updateShowOnlyDate}
                    >
                      <i className="material-icons">close</i>
                    </button>
                  </span>
                
              </div>
            }
            visible={this.state.showOnlyDate}
            size="xl"
            modalBackdropClicked={null}
            body={
              <div className="row">
                <div className="col-lg-3">
                  {!readOnly && (
                    <ChooseEngineer
                      onClassChange={this.props.onClassChange}
                      onChunkChange={this.props.onChunkChange}
                      userId={this.props.userId}
                      readOnly={this.props.readOnly}
                      rotationClass={this.props.rotationClass}
                      rotationChunk={this.props.rotationChunk}
                      onEngineerChange={this.props.onEngineerChange}
                      peopleInBacklog={this.props.backlogUsers}
                    />
                  )}
                </div>
                <div className={readOnly ? "col-lg-12" : "col-lg-9"}>
                  <ShowOnlyDate
                    onSubmittedRotationsUpdate={this.onSubmittedRotationsUpdate.bind(
                      this
                    )}
                    isRotationEngIndiaOrUSComplaint={this.isRotationEngIndiaOrUSComplaint.bind(
                      this
                    )}
                    areRotationsUSCompliant = {this.areRotationsUSCompliant.bind(this)}
                    readOnly={this.props.readOnly}
                    getRotationHours={this.getRotationHours.bind(this)}
                    isAlreadySubmitted={this.isAlreadySubmitted.bind(this)}
                    rotations={this.props.rotations}
                    removedRotations={this.props.removedRotations}
                    updateShowOnlyDate={this.updateShowOnlyDate.bind(this)}
                    getRotationDetails={this.getRotationDetails.bind(this)}
                    selectedDate={selectedDate}
                    previewOnly={previewOnly}
                    oncallTimes={this.props.oncallTimes}
                    timeZone={this.props.timeZone}
                    peopleInBacklog={this.props.backlogUsers}
                    submittedRotations={this.state.submittedRotations}
                    selectedRotationUser={this.props.rotationUser}
                    selectedRotationBacklog={this.props.rotationBacklog}
                    selectedRotations={this.state.selectedRotations}
                    updateSelectedRotations={this.updateSelectedRotations.bind(
                      this
                    )}
                    deleteSelectedRotations={this.deleteSelectedRotations}
                  />
                </div>
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default Calendar;
