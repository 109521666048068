import React from 'react';

import { getLocalizedTime, isBetweenDate, getCurrrentUTCDate } from '../../../momentTools';

const Teams = ({ timeZone, team, holidays }) => {
  let getTeamStatus = () => {
    let currentDate = getCurrrentUTCDate('YYYY-MM-DDTHH:mm');
    let currentDay = getCurrrentUTCDate("YYYY-MM-DD");
    let startBacklogDate = currentDay + "T" + team.WorkingHoursStart;
    let endBacklogDate = currentDay + "T" + team.WorkingHoursEnd;
    let isOpenOffice = isBetweenDate(currentDate, startBacklogDate, endBacklogDate);

    let teamLocation = team.LocationID.split('-')[0];
    let isHoliday = holidays[currentDay.replace(/-/g,"")] && holidays[currentDay.replace(/-/g,"")][teamLocation];

    return { isOpen: isOpenOffice && !isHoliday, isClosed: !isOpenOffice && !isHoliday, isHoliday: isHoliday};
  }

  let teamStatus = getTeamStatus();//1.9.4-1 Get team status if holiday or open or close

  return (
    <div className={"card team-info my-4 mr-2 text-center" + (teamStatus.isOpen ? " active-team" : "")} key={team.ID}>
      <div className="card-body">
        <h5 className="card-title">
          {team.Name}
          {teamStatus.isOpen && <span className={"card-subtitle badge text-white ml-2 bg-teal"}>OPEN</span>}
          {teamStatus.isClosed && <span className={"card-subtitle badge text-white ml-2 bg-dark-grey"}>CLOSED</span>}
          {teamStatus.isHoliday && <span className={"card-subtitle badge text-white ml-2 bg-orange"}>HOLIDAY</span>}
        </h5>
        <div className="card-text">
          <div className="d-inline-block float-left">
            <i className="material-icons">location_on</i>{team.LocationName.split(" ").pop(-1)}
          </div>
          <div className="d-inline-block float-right">
            <i className="material-icons">access_time</i>{getLocalizedTime(team.WorkingHoursStart, timeZone)} - {getLocalizedTime(team.WorkingHoursEnd, timeZone)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Teams;
