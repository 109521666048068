import React, {Component} from 'react';
// import ReactExport from 'react-export-excel';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { getNumberDayofWeek, formatDate } from '../../momentTools';
import './CompensationTable.css';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelStyles = {
  Default: {font: {sz: "10"}},
  Header: {font: {sz: "12", bold: true}}
}

let getRate = (rotation, location, rates, excelRow) => {
  if(!rotation){
    (excelRow && excelRow.push(""));
    return "";
  }
 // debugger;
  if(rotation.Type === 'Weekday' || location === 'PL' || location === 'NL'){
    (excelRow && excelRow.push(rates[location][rotation.Type]));
    return rates[location][rotation.Type];
  } else {
    (excelRow && excelRow.push(rates[location][rotation.Type][rotation.Chunk]));
    return rates[location][rotation.Type][rotation.Chunk];
  }
}

let getTotalCompensation = (userRotations, countryCode, rates, excelRow) => {
  let totalCompensation = 0;

  Object.keys(userRotations).map((date, key) => {
    return totalCompensation += getRate(userRotations[date], countryCode, rates);
  })

  excelRow.push(totalCompensation);

  return totalCompensation;
}

let isWeekendOrHoliday = (date, holidays) => {
  if(getNumberDayofWeek(date) % 6 === 0 || holidays[date.replace(/-/g,"")]){
    return "comp-table-cell-holiday"
  }
}

class CompensationTable extends Component {
  render(){
    //debugger;
    let {Report, compensation, holidays, rates, showLarge} = this.props;

    let multiDataSet = [
      {
        columns: ["Name", "Pega ID", "Location", "Currency", "Total Compensation"],
        data: []
      }
    ];
    multiDataSet[0].columns.push(...Report.dates);


    return(
      <React.Fragment>
        {/* <ExcelFile filename={"Engineering On-Call Compensation " + Report.month} element={<button>Download Report</button>}>
            <ExcelSheet dataSet={multiDataSet} name="Compensation"/>
        </ExcelFile> */}

        <StickyTable id="comp-table" className={(showLarge ? "large": "")}>
            <Row>
                <Cell className="comp-table-header">Name</Cell>
                <Cell className="comp-table-header">Pega ID</Cell>
                <Cell className="comp-table-header">Location</Cell>
                <Cell className="comp-table-header">Currency</Cell>
                <Cell className="comp-table-header">Total<br />Compensation</Cell>
                {
                  Report.dates.map((date) => {
                    return (
                      <Cell key={date} className={"comp-table-header " + isWeekendOrHoliday(date, holidays)}>
                        {formatDate(date, "MM/DD")}<br />{formatDate(date, "ddd")}
                      </Cell>
                    )
                  })
                }
            </Row>
              {
                Object.keys(compensation).map((location, key) => {
                  return Object.keys(compensation[location]).map((user, key) => {
                   // debugger;
                    let userRotations = compensation[location][user];
                    let firstRotation = userRotations[Object.keys(userRotations)[0]];
                    let countryCode = location.split('-')[0];
                    
                    var excelRow = [
                      firstRotation.FirstName + " " + firstRotation.LastName,
                      firstRotation.ID,
                      location,
                      rates[countryCode].Currency
                    ];
                    multiDataSet[0].data.push(excelRow);

                    return (
                      <Row key={firstRotation.ID}>
                        <Cell className="comp-table-body">{firstRotation.FirstName + " " + firstRotation.LastName}</Cell>
                        <Cell className="comp-table-body">{firstRotation.ID}</Cell>
                        <Cell className="comp-table-body">{location}</Cell>
                        <Cell className="comp-table-body">{rates[countryCode].Currency}</Cell>
                        <Cell className="comp-table-body">
                          {
                            getTotalCompensation(userRotations, countryCode, rates, excelRow)
                          }
                        </Cell>
                        {
                          Report.dates.map((date) => {
                            //console.log(userRotations[date]);
                            //debugger;
                            return (
                              <Cell className="comp-table-body" key={firstRotation.ID + "_" + date}>
                                {
                                  getRate(userRotations[date], countryCode, rates, excelRow)
                                }
                              </Cell>
                            );
                          })
                        }
                      </Row>
                    )
                  })
                })
              }
          </StickyTable>
        </React.Fragment>
      )
    }
  }

export default CompensationTable;
