import React from 'react';
import './ProgramOutline.css';
import {FAQ} from './FAQ';
import QuestionItem from './QuestionItem';


const ProgramOutline = () => (
  <div className="ProgramOutline" >
  <h1 className="section-header">FAQ</h1>
    <div className="row">
      <div className="col-md-6 text-left">
        <p className="faq-section-header text-yellow">Program Outline</p>
        <div className="faq-content-box bg-yellow">
          <p className="faq-question-header font-extra-bold text-white">WHY ARE WE DOING IT?</p>
          <p className="font-light">Ensure Pega provides our customers a seamless and frictionless support experience as we transition to an “as a Service” company – Our number one goal is customer success.</p>
        </div>
        <div className="text-light-grey faq-outline-icon"><p>&#11206;</p></div>
        <div className="faq-content-box bg-yellow">
          <p className="faq-question-header font-extra-bold text-white">HOW DO WE WANT TO ACHIEVE IT?</p>
          <p className="font-light">Implement an Engineering On-Call program, covering the main backlogs, capable of providing 24x7 technical expertise for customer escalations that occur after working hours and during weekends.</p>
        </div>
        <p className="faq-question-header font-extra-bold text-yellow pt-4">HOW THIS PROGRAM FITS TO THE ORGANIZATION?</p>
        <p className="font-light">The Global Customer Support team receives and resolves all kinds of issues reported by Pega’s customers. Most of the problems, including improper use or configuration of the services, are resolved at this point. However, some of the reported issues require additional assistance from engineering or changes to the product. The SWAT teams handle these requests during standard working hours. This new Engineering On-Call structure will cover after-hours, weekends and holidays.</p>
        <p className="faq-question-header font-extra-bold text-yellow">HOW IS THIS DIFFERENT FROM WHAT WE HAD?</p>
        <p className="font-light">The significant change lays in introducing the On-Call schedule and 24x7 coverage for all Level 1 Feature backlogs. Prior to this change, there was no schedule in place, and backlog experts were identified and approached ad hoc. Thanks to this change in coverage and criteria, the GCS and SRT teams contact the On-Call person directly, rather than <i>“dialing for dollars”</i> and trying to reach someone after-hours. The compensation for On-Call duty is a new benefit for employees as well.</p>
        <p className="text-teal font-light">The outcome of the change is a faster response to technical problems with the right technical expertise, to ensure better-enabled, happier customers.</p>
        <img className="faq-image" src={require(`./change.png`)} alt="Change"/>
        <p className="faq-question-header font-extra-bold text-yellow pt-4">ESCALATION LEADERSHIP</p>
        <p className="font-light">Global Customer Support Leadership (GCSSELT@pega.com)<br />Service Reliability (SRT-Leadership@pega.com)</p>
        <p className="faq-question-header font-extra-bold text-yellow">PROGRAM MANAGER</p>
        <p className="font-light">Suman Bokkasam<br />Webex: Suman.Bokkasam@in.pega.com<br />Mobile: (+91) 9959623049</p>
        <p className="faq-question-header font-extra-bold text-yellow">ONCALL APP PRODUCT MANAGER</p>
        <p className="font-light">Ravi Krishna Chavali<br />Webex: RaviKrishna.Chavali@in.pega.com<br />Mobile: (+91) 9505901757</p>
        <p className="faq-question-header font-extra-bold text-yellow">ONCALL Webex Support</p>
        <p className="font-light"><a target="_blank" href="webexteams://im?space=a948e4e0-edcf-11e8-bf73-7ff9f24359b6"> OnCall Space</a></p>
        <p className="faq-question-header font-extra-bold text-yellow">Found a Bug?</p>
        <p className="font-light"><a target="_blank" href="https://agilestudio.pega.com/prweb/AgileStudio/app/agilestudio/backlog/BL-10101"> Please log here.</a></p><br />
      </div>
      <div className="col-md-6 text-left pb-5">
        <p className="faq-section-header text-teal">{FAQ.Nominated.title}</p>
        {
          FAQ.Nominated.questions.map((item, index) => <QuestionItem key={index} item={item} color="teal" />)
        }
        <p className="faq-section-header text-orange pt-5">{FAQ.OnCall.title}</p>
        {
          FAQ.OnCall.questions.map((item, index) => <QuestionItem key={index} item={item} color="orange" />)
        }
      </div>
    </div>
  </div>
)

export default ProgramOutline;
