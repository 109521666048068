import React, {Component} from 'react';

import {getUser, postUser, updateUser} from '../../../../api.js';
import MyModal from '../../../Modal';

class AddUser extends Component {
  state = {
    userId: '',
    user: null,
    isUserId: false,
    error: null,
  }

  handleOnChangeUserID = handler => {
    this.setState({userId: handler.target.value.toUpperCase()});
  }

  onClickSearchUser = () => {
    const {userId} = this.state;

    if (userId !== '') {
      this.setState({error: '', isUserId: false});

      getUser(userId)
        .then(res => {
          if (res.message) {
            this.setState({error: res.message})
          } else if (res[userId].backlogId) {
            this.setState({error: `User assigned to backlog (${res[userId].backlogId})!`})
          } else {
            this.setState({
              user: res[userId],
              isUserId: true
            })
          }
        })
    }
  }

  addNewUserToDB = (user, backlogId, showToast=false) => {
    console.log("user before add"+JSON.stringify(user));
    // const data = {
    //   userId: user.EmpID,
    //   firstName: user.FirstName,
    //   lastName: user.LastName,
    //   displayName: user.FirstName,
    //   email: user.EmailAddress,
    //   backlogId: backlogId,
    //   jobTitle: user.Title,
    //   location: user.LocationCode,
    //   phonePrimary: undefined,
    //   phoneFallback:undefined,
    //   grade: user.GradeCode,
    //   managerId: user.ManagerID,
    //   isManager: (user.IsManager === 'true' ? 1 : 0),
    //   role: 'eng',
    //   timezone: 'Etc/UTC'
    // }

    // "Timezone":"Etc/UTC","BacklogID":"","Email":"ravikrishna.chavali@in.pega.com","pxUpdateSystemID":"c3900a-test","pxUpdateDateTime":"20220426T142414.232 GMT","pxUpdateOpName":"Admin Oncall","pxUpdateOperator":"noor.oncall","TeamID":"","JobTitle":"Associate Architect","pySourcePage":{"pySourceIdentifier":"Users","pySourceNumber":"1","pySourceType":"Lookup","pySourceClass":"PS-OnCall-Data-Users"},"pxCreateDateTime":"20220426T142414.232 GMT","DisplayName":"Ravi Krishna","pxDPParameters":{"ID":"CHAVR"},"ID":"CHAVR","ManagerID":"BOKKS","PhoneFallback":"","pxSaveDateTime":"","pyAccessGroup":"OnCall:Admin","FirstName":"Ravi Krishna","pzLoadTime":"April 29, 2022 11:00:13 AM UTC","pzPageNameHash":"_pa369560849291946pz","pzInsKey":"PS-OnCall-Data-Users CHAVR","Grade":"","PhonePrimary":"7676774767","pzPageNameBase":"D_Users","IsManager":1,"Role":"admin","pxCreateOperator":"noor.oncall","pxCreateSystemID":"c3900a-test","LastName":"Chavali","pxCommitDateTime":"","pxCreateOpName":"Admin Oncall","Location":"IN-TS-HBD"}


    const data = {
      userId: user.ID,
      firstName: user.FirstName,
      lastName: user.LastName,
      displayName: user.FirstName,
      email: user.Email,
      backlogId: backlogId,
      jobTitle: user.JobTitle,
      location: user.Location,
      phonePrimary: user.phonePrimary,
      phoneFallback: user.phoneFallback,
      grade: user.Grade,
      managerId: user.ManagerID,
      isManager: (user.IsManager === 'true' ? 1 : 0),
      role: 'eng',
      timezone: 'Etc/UTC'
    }


    
    postUser(data).then( res => {
      if(showToast){
        this.props.onSuccessAdd(res.message);
        this.props.handleToggle();
      }
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    if (!this.state.user) return;

    if (this.state.user.source === 'LOCAL') {
      updateUser(this.state.user.ID, {name: "backlogId", value: this.props.backlog.ID}).then( res => {
          this.props.onSuccessAdd();
          this.props.handleToggle();
      });
    } else {
      this.addNewUserToDB(this.state.user, this.props.backlog.ID, true);
    }

    let managerId = this.state.user.ManagerID || this.state.user.managerId;
    getUser(managerId).then( user => {
      if (user[managerId] && user[managerId].source === 'LOCAL') {
        return;
      } else {
        this.addNewUserToDB(user[this.state.user.ManagerID]);
      }
    });
  }

  render() {
    const {user, isUserId, error} = this.state;

    return(
      <MyModal
        visible={true}
        modalBackdropClicked={this.props.handleToggle}
        header={"Add On-Call Engineer"}
        body={
          <div className="AddUser">
            <div className="col-sm-12">
              <div className="row">
                <div className="mx-auto text-left"  style={{minWidth: '100%'}}>
                  <div className="form-group">
                    <label htmlFor="backlog">Selected backlog</label>
                    <input className="form-control" type="text" defaultValue={`${this.props.backlog.Name} (${this.props.backlog.ID})`} readOnly />
                  </div>

                  <label htmlFor="userId">User ID</label>
                  <div className="input-group mb-3">
                    <input type="text"
                           className="form-control"
                           id="userId"
                           name="userId"
                           value={this.state.userId}
                           autoComplete="off"
                           aria-describedby="userIdHelp"
                           placeholder="Enter userId"
                           onChange={this.handleOnChangeUserID}/>

                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary" type="button" onClick={this.onClickSearchUser}>Search</button>
                    </div>
                  </div>


                  {error && <div className="backlogInfo alert alert-danger text-left"><b>{error.toString()}</b></div>}

                  {isUserId && <div className="backlogInfo alert alert-info text-left">
                    <p><b>ID:</b> {user.EmpID || user.ID}</p>
                    <p><b>Name:</b> {user.FirstName || user.firstName} {user.LastName || user.lastName}</p>
                    <p><b>Email:</b> {user.EmailAddress || user.email}</p>
                    <p><b>ManagerID:</b> {user.ManagerID || user.ManagerID}</p>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        }
        btnPrimary={{
          text:"Add On-Call Engineer",
          color:"btn-success",
          onClick: this.handleSubmit
        }}
        btnSecondary={{
          text:"Cancel",
          color:"btn-secondary",
          onClick: this.props.handleToggle
        }}
        />
    )
  }
}

export default AddUser;
