import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import MyModal from '../../Modal';
import ContactButtonItem from './ContactButtonItem';

const ContactButton = ( {teams, rotations, timeZone, toggleModal}) => {// 1.7.14 -  contact information - deprecated
  const copyToClipboard = (data) => {
    toast.success('Copied to the clippboard!', {position: toast.POSITION.TOP_RIGHT});
    return navigator.clipboard.writeText(data);
  }

  return (
    <MyModal
      visible={true}
      size='lg'
      modalBackdropClicked= {toggleModal}
      header={"Contact"}
      body={
        <div className="Contact">
          <ToastContainer autoClose={2000} hideProgressBar={true}/>
          <div className="col-sm-12">
            {
              teams && Object.keys(teams).map( (key, index) =>
                <ContactButtonItem item={teams[key]} timeZone={timeZone} copyToClipboard={copyToClipboard}/>
              )
            }

            {
              rotations && Object.keys(rotations).map( (key, index) =>
                <ContactButtonItem item={rotations[key]} timeZone={timeZone} copyToClipboard={copyToClipboard}/>
              )
            }
          </div>
        </div>
      }
      btnSecondary={{
        text:"Cancel",
        color:"btn-secondary",
        onClick: toggleModal
      }}
      />
  )
}


export default ContactButton;
