import React from 'react';
import RotationListItem from './RotationListItem';

const RotationList = ({ rotations, timeZone, type,onSelectedRotationsUpdate}) => {
  const rotationsClone = {...rotations}
  const updateRotation = (rotation) => {
    rotationsClone[rotation.Day][rotation.ID] = rotation;
    onSelectedRotationsUpdate(rotationsClone);
  }
  return (
    <div className="col-sm-5 mr-3">
      <h4 className="pb-2">{type}</h4>
      <ul className="list-group h6 small">
        {
          Object.keys(rotations).sort().map((rotationDay) => {
            return Object.keys(rotations[rotationDay]).map((rotationUserID, index) => {
              let rotation = rotations[rotationDay][rotationUserID];
              return <RotationListItem key={index} rotation={rotation} handleChange={updateRotation} timeZone={timeZone} />
            })
          })
        }
      </ul>
    </div>
  )
}

export default RotationList
