import React, {Component} from 'react';

import './Settings.css'
import TimezoneSelector from '../TimezoneSelector';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      filterButtonsValue: undefined
    }
  }

  resetFilterButtons = () => {
    this.setState({filterButtonsValue: undefined});
  }

  resetSearchBar = () => {
    this.textInput.current.value = '';
  }

  onSearchBarChange = (value) => {
    this.resetFilterButtons();
    this.props.searchBacklogsList('Name', value);
  }

  onFilterButtonsClick = (value) => {
    if(this.state.filterButtonsValue === value){
      this.resetFilterButtons();
      this.props.resetBacklogsList();
    } else {
      this.setState({filterButtonsValue: value});
      this.resetSearchBar();
      this.props.filterBacklogsList(value);
    }
  }

  render() {
    return (
      <div className="settings col-sm-12">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="row">
              <div className="col-sm-12">
                <div className="searchBar">
                  <div className="form-group has-search">
                    <i className="material-icons form-control-feedback">search</i>
                    <input type="text"
                           className="form-control"
                           placeholder="Search"
                           onChange={(event) => this.onSearchBarChange(event.target.value)}
                           ref={this.textInput}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-sm-12">
                <div className="btn-group btn-group-toggle d-flex" data-toggle="buttons" ref="radioInputs">
                  <label className={!this.state.filterButtonsValue || this.state.filterButtonsValue === 'ON-CALL' ? "btn btn-warning w-100" : "btn btn-secondary w-100"} type="radio" htmlFor="customRadio2">
                    <input type="radio"
                           id="customRadio2"
                           name="customRadio"
                           value={'ON-CALL'}
                           onClick={(event) => this.onFilterButtonsClick(event.target.value)} />On&nbsp;Call
                  </label>
                  <label className={!this.state.filterButtonsValue || this.state.filterButtonsValue === 'OPEN OFFICE' ? "btn btn-primary w-100" : "btn btn-secondary w-100"} type="radio" htmlFor="customRadio3">
                    <input type="radio"
                           id="customRadio3"
                           name="customRadio"
                           value={'OPEN OFFICE'}
                           onClick={(event) => this.onFilterButtonsClick(event.target.value)} />Open&nbsp;Office
                  </label>
                  <label className={!this.state.filterButtonsValue || this.state.filterButtonsValue === 'NO COVERAGE' ? "btn btn-danger w-100" : "btn btn-secondary w-100"} type="radio" htmlFor="customRadio4">
                    <input type="radio"
                           id="customRadio4"
                           name="customRadio"
                           value={'NO COVERAGE'}
                           onClick={(event) => this.onFilterButtonsClick(event.target.value)} />No&nbsp;Coverage
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-8">
            <TimezoneSelector setTimezone={(timeZone) => this.props.setTimezone(timeZone)} getTimezone={this.props.getTimezone} ActiveUser={this.props.ActiveUser} />
          </div>
        </div>
      </div>
    )
  }
}


export default Settings;
