import React from "react";
import { getLocalizedTimefromDate } from "../../../momentTools";

const ContactButtonItem = ({ item, timeZone, copyToClipboard }) => {// 1.9.1 SHow contact details
  const label = item.name || item.UserDisplayName + " " + item.UserLastName;
  const id = item.UserID || item.ID;
  const location = item.UserLocationName || item.LocationName;
  const start = item.StartTime || "2000-01-01T" + item.WorkingHoursStart;
  const end = item.rotationEnd || "2000-01-01T" + item.WorkingHoursEnd;
  const email = item.UserEmail;
  const phone = item.UserPhonePrimary || item.ManagerPhonePrimary;
  const managerName = item.ManagerFirstName
    ? item.ManagerFirstName + " " + item.ManagerLastName
    : undefined;

  const isTeam = id.indexOf("PROJ-") === 0;

  return (
    <div className="list-group contact-button-item mr-2" key={item.ID}>
      <div className="list-group-item pr-0">
        <div className="row">
          <div className="col-12">
            <h5 className="text-left float-left text-teal font-extra-bold">
              {label} ({id})
            </h5>
            <small className="float-right right-10">
              <span className="right-10">
                <i className="material-icons pr-1">location_on</i>
                {location.split("-").pop().trim()}
              </span>
              <span>
                <i className="material-icons pr-1">access_time</i>
                {getLocalizedTimefromDate(start, timeZone, "HH:mm")} -{" "}
                {getLocalizedTimefromDate(end, timeZone, "HH:mm")}
              </span>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 pr-0">
            <h6 className="pt-1 text-left">
              {isTeam ? "Team Manager" : "Email"}
            </h6>
            <small className="float-left">
              <i className="material-icons float-left">
                {isTeam ? "people" : "email"}
              </i>
              <button
                className="btn btn-sm pt-0"
                onClick={() => {
                  copyToClipboard(isTeam ? managerName : email);
                }}
              >
                {isTeam ? managerName : email}
              </button>
            </small>
          </div>
          <div className="col-sm-6">
            <h6 className="pt-1 text-left">
              {isTeam ? "Manager's Phone" : "Phone"}
            </h6>
            <small className="float-left">
              <i className="material-icons float-left">phone</i>
              <button
                className="btn btn-sm pt-0"
                onClick={() => {
                  copyToClipboard(phone);
                }}
              >
                {phone}
              </button>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactButtonItem;
