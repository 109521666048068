import React, {Component} from 'react';
import MyModal from '../Modal';
import {Timezones} from '../Timezones';
import {updateUser, getLocations, getBacklogs} from '../../api.js'

class EditProfile extends Component {
  state = {
    editedValue: null,
    selectValues: [],
    disableButtons: false
  }

  nameData = this.props.editData;
  valueData = this.props.user[this.nameData];

  componentDidMount() {
    if (this.nameData === 'Location') {
      getLocations().then( result => {
        let locations = {};
        Object.keys(result).map( (key, value) => {
          return locations[result[key].id] = result[key];
        });
        this.setState({selectValues: locations});
      })
    }

    if (this.nameData === 'BacklogID') {
      getBacklogs().then( result => {
        let backlogs = {};
        Object.keys(result).map( (key, value) => {
          return backlogs[result[key].id] = result[key]
        });
        this.setState({selectValues: backlogs});
      })
    }

    if (this.nameData === 'Timezone') {
      this.setState({selectValues: Timezones});
    }
  }

  onEditData = () => {
    this.props.toggleLoader(true)
    updateUser(this.props.user.ID, this.state.editedValue)
        .then(res => {
          this.props.onSuccessEdit(res.message);
          this.props.dissmissModal();
          this.props.toggleLoader(false)
        })
        .catch(res => {
          console.log(res)
          this.props.onFailureEdit(res.message);
          this.props.dissmissModal();
          this.props.toggleLoader(false)
        })
  }

  onCancel = () => {
    this.props.dissmissModal()
  }

  validatePhoneNumber = handler => {
      let regex = new RegExp("^[0-9]+$");
      this.setState({ disableButtons : false})
      regex.exec(handler.target.value) ? this.handleDataChange(handler) :  this.setState({ disableButtons : true})
  }

  handleDataChange = handler => {
    this.setState({
      editedValue: {
        name: handler.target.name,
        value: handler.target.value
      }
    })
  }

  renderInput = (nameData) => {
    switch( nameData ) {
      case 'Location':
      case 'BacklogID':
      case 'PhonePrimary': 
        return <input type="num" className="form-control" name={this.nameData} defaultValue={this.valueData} onChange={this.validatePhoneNumber}/>
      case 'Timezone':
        return (
          <select className="form-control" name={this.nameData} value={this.valueData} onChange={this.handleDataChange}>
          {
            Object.keys(this.state.selectValues).map( (key, value) =>
            <option key={this.state.selectValues[key].id}
                    value={this.state.selectValues[key].id}>
                {this.state.selectValues[key].name} ({this.state.selectValues[key].id})
            </option>
            )
          }
          </select>
        )
      default:
        return <input type="text" className="form-control" name={this.nameData} defaultValue={this.valueData} onChange={this.handleDataChange} />
    }
  }


  render() {
    return (
      <MyModal
        visible={true}
        modalBackdropClicked={this.props.dissmissModal}
        header={
          <div>
            {"Edit"}     {this.props.labels[this.nameData]}
            <span className="rt-modal-close">
              <button
                type="button"
                className="btn btn-default"
                onClick={this.props.dissmissModal}
              >
                <i className="material-icons">close</i>
              </button>
            </span>
          </div>
        }
        body={
          <div className="EditProfile">
            <div className="col-sm-12">
              <div className="row">
                <div className="mx-auto text-left" style={{minWidth: '100%'}}>
                  <div className="form-group">
                    {/* <label className="font-weight-bold">{this.props.labels[this.nameData]}</label> */}
                    {this.renderInput(this.nameData)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        btnSecondary={{
          text:"Cancel",
          color:"btn-danger",
          onClick: this.onCancel,
        }}
        btnPrimary={{
          text:"Save",
          color:"btn-primary",
          disableButtons: this.state.disableButtons,
          onClick: this.onEditData
        }}
        />
    )
  }
}

export default EditProfile;
