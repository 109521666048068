import React from 'react';

const QuestionItem = ({item, color}) => (
  <div>
    <p className="faq-question-header font-bold">
      <i className={`material-icons text-${color} faq-question-icon`}>chat</i>
      {item.question}
      {item.updated && <span className="text-white bg-yellow faq-question-updated">UPDATED</span>}
    </p>
    <p className="faq-question-body">{item.answer}</p>
    {item.note && <div className="container"><i className="material-icons text-orange faq-note-icon">error_outline</i><p className="faq-note-text font-condensed">{item.note}</p></div>}
    {item.image && <img className="faq-image" src={require(`./${item.image}`)} alt={item.image}/>}
  </div>
)

export default QuestionItem;
