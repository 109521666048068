import React, {Component} from 'react';
import MonthPicker from 'react-simple-month-picker';
import { formatDate } from '../momentTools';
import { getReports } from '../api';
import { ToastContainer, toast } from 'react-toastify';

class Reports extends Component {
  state = {
    lastReports: {},
  }
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.message) {
      toast.success(this.props.location.state.message, {position: toast.POSITION.TOP_RIGHT})
    }
    getReports(4).then( res => this.setState({lastReports: res}) );
    document.onkeyup = (e) => {
      if (e.altKey && e.which === 84) {
        e.preventDefault();
        this.setState({isTableLarge: !this.state.isTableLarge})
      }
    };
  }

  handleDateChangeGenerate = (date) => {
    const newDate = formatDate(date, "YYYY-MM");
    this.props.history.push(`/reports/compensation/${newDate}`);
  }

  handleDateChangeChoose = (fileName) => {
    this.props.history.push(`/reports/compensation/${fileName}`);
  }


  render() {
    return (
      <div className="Reports">
      <ToastContainer/>
        <div className="row">
          <div className="col-sm-12">
            <h1>Compensation Report</h1>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-5">
            <h2>GENERATE</h2>
            <div className="row">
              <div className="col-sm-12 pb-2">
                <MonthPicker onChange={this.handleDateChangeGenerate} />
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <h2>OR</h2>
          </div>
          <div className="col-lg-5">
            <div className="row mx-auto"><h2 className="mx-auto">CHOOSE</h2></div>
            <div className="row pt-3">
              <div className="col-sm-12">
                <ul className="list-group">
                  {Object.keys(this.state.lastReports).sort().reverse().map( (key,index) => {
                    const createDate = formatDate(this.state.lastReports[key].createDate, "YYYY-MM-DD HH:mm");
                    return (
                      <div className="list-group-item list-group-item-action flex-column align-items-start" onClick={() => this.handleDateChangeChoose(this.state.lastReports[key].fileName)} key={index}>
                        <div className="row">
                          <div className="col px-0"><p className="h6">{this.state.lastReports[key].fileName}</p></div>
                          <div className="col px-0"><small className="align-top"><i className="small material-icons ">calendar_today</i>{this.state.lastReports[key].month}</small></div>
                          <div className="col px-0"><small className="align-top"><i className="small material-icons">create</i>{createDate}</small></div>
                        </div>
                      </div>
                    )
                })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reports;
