import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { getLoggedUser } from "./api";
import "./App.css";
import Coverage from "./components/Coverage";
import Navbar from "./components/Navbar";
import Profile from "./components/Profile/Profile";
import ProgramOutline from "./components/ProgramOutline/ProgramOutline";
import Reports from "./components/Reports";
import Compensation from "./components/Reports/Compensation";
import Rotation from "./components/Rotation";
import Schedule from "./components/Schedule";
import Backlog from "./components/Schedule/Backlog/Backlog";
// import TeamsView from "./components/TeamsView";
import CallSME from "./components/CallSME/CallSME";



const Authorization = (allowedRoles) => (WrappedComponent) => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        user: {},
      };
    }

    componentDidMount() {
      
        // FTR-1.1.1 
        getLoggedUser().then((users) => {
          this.setState({ user: users[Object.keys(users)[0]] || {} });
        })
        .catch((e) => {
          console.error(e.message);
        })
      /*} else {
        this.setState({
          user: {
            id: "bokks",
            firstName: "John",
            lastName: "Doe",
            displayName: "John Doe",
            email: "john.doe@pega.com",
            backlogId: "GRP-13931",
            teamId: null,
            jobTitle: "Software Engineer",
            location: "IN-TS-HBD",
            phonePrimary: "",
            phoneFallback: null,
            grade: "7",
            managerId: "SHAIA",
            isManager: 0,
            role: "admin",
            timezone: "Etc/UTC",
          },
        });
      }*/
    }

    render() {
      // UC-1.2.1
      const role =
        !this.state.user || !this.state.user.role
          ? "guest"
          : this.state.user.role;
      // UC-1.2.2   
      if (allowedRoles.includes(role)) {
        return (
          <WrappedComponent
            {...this.props}
            ActiveUser={this.state.user}
            Roles={Roles}
            editRotationAccess={(role === "rep" || role === "admin")}
          />
        );
      } else {
        // UC-1.2.3  
        return <h1></h1>;
      }
    }
  };
};

// UC-1.3.1
const Roles = {
  Global: [ "el", "eng", "rep", "admin","guest"], //Gues
  EscalationLeadership: ["el", "admin"], // AG - OnCall:GCSManagers //GCS Engineer
  OnCallEngineer: ["eng", "rep", "admin"], // Access Group - OnCall:SME , //SME Engineer
  BacklogRepresentative: ["rep", "admin"], // Roster Manager
  Administrator: ["admin"], //Admin
};
// UC-1.3.2
const Global = Authorization(Roles.Global);
const EscalationLeadership = Authorization(Roles.EscalationLeadership);
const OnCallEngineer = Authorization(Roles.OnCallEngineer);
const BacklogRepresentative = Authorization(Roles.BacklogRepresentative);
const Administrator = Authorization(Roles.Administrator);

function App() {
  //UC-1.4.1
  const WrappedNavbar = Global(Navbar);

  return (
    <div className="App">
      {/* UC-1.5.1 Define the routes */}
      <BrowserRouter>
        <WrappedNavbar />
        <div className="container pt-5">
          <Switch>
            
            <Redirect exact from="/" to="/coverage" /> {/* UC-1.5.2 Redirect root to coverage */}
            <Route exact path="/backlog" component={OnCallEngineer(Schedule)} /> {/* UC-1.5.3 Backlog link navigates to show Schedule component only for admins  */}
            <Route path="/backlog/:backlogId" component={OnCallEngineer(Backlog)} /> {/* UC-1.5.4 BacklogID link navigates to Backlog component only for users with rep/eng/admin role */}
            <Route exact path="/coverage" component={Global(Coverage)} /> {/* UC-1.5.5 coveraqe link navigates to Coverage component for all */}
            <Route exact path="/callSME" component={EscalationLeadership(CallSME)} /> {/* UC-1.5.6 CallSME link navigates to CallSME component only for EscalationManagers */}
            <Route 
              exact
              path="/rotation"
              component={BacklogRepresentative(Rotation)}
            />{/* UC-1.5.7 BacklogID link navigates to Backlog component only for Backlog Reps */}
            {/*<Route exact path="/team" component={TeamsView} />*/}{/* UC-1.5.8 team link navigates to TeamsView component NOT IMPL */}
            <Route
              exact
              path="/rotation/:backlogId"
              component={BacklogRepresentative(Rotation)}
            />{/* UC-1.5.9 edit schedule link opens Rotation for backlog only for Backlog Reps */}
            <Route exact path="/profile/:userId" component={Global(Profile)} />{/* UC-1.5.10 profile link opens user profile */}
            <Route exact path="/reports" component={Administrator(Reports)} />{/* UC-1.5.11 reports link opens reports page */}
            <Route exact path="/reports/compensation/:month" component={Administrator(Compensation)}/>{/* UC-1.5.12 Compensation page opens onclick of the month only for admins (not required in new model)  */}
            <Route exact path="/faq" component={Global(ProgramOutline)}  />{/* UC-1.5.13 FAQ link opens ProgramOutline component   */}
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}
export default App;
