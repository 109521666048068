import React, { useEffect, useRef, useState } from "react";
import { postOnCall } from "../../api";
import "./CallSME.css"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

export default function SMEConnect({ data: { smeDetails: smeDetails, ticket: ticket }, modifySME  }  ) {
    let history = useHistory();
    const [smeDetailsList, setSMEdetailsList] = useState(smeDetails.SMEList);
    let [smeTimerObj, sestSmeTimerObj] = useState({});
    const attemptMap = { 1: "first", 2: "second", 3: "third" };
    let smeIntervalIDObj = {};
    useEffect(()=>{
        try{
            const smeList = smeDetails.SMEList.map((val, idx)=>{
                sestSmeTimerObj((prev) => {
                    return {
                        ...prev,
                        ...{
                            [idx]: 10,
                        }
                    }
                })
                return {
                    ...val,
                    timer: 10,
                    wait: false,
                    attempt: 1,
                    webex: false,
                    picked: false,
                    phoneShow: true,
                    unavailable: false,
                    confirmed: false,
                    newEngineer: "",
                    intervalTimer: null
                }
            })
            setSMEdetailsList(smeList);
        }catch(e){
            console.log("Something went wrong!")
        }
    }, [smeDetails])

    const setOnClickActions = (actionsObj, actIndex) => {
        const clickActArr = Object.keys(actionsObj);
        // const actIndex = actionsObj["index"];
        if(clickActArr.indexOf("attempt")>=0){
            initTimer(actIndex, actionsObj.attempt);
            return;
        }
        const clickActObj = clickActArr.reduce((obj, clickAct) => {
            return {
               ...obj,
              [clickAct] : actionsObj[clickAct]
            }
        }, {})
        setSMEdetailsList((prev) => {
            return prev.map((val, preIdx)=> {
                if(preIdx === actIndex && clickActArr.indexOf("attempt") < 0){
                    return {
                        ...val,
                        ...clickActObj
                    }
                }
                return val;
            })
        })
    }
    
    const initTimer = (actIndex, attempt = 0) => {
        setSMEdetailsList((prev) => {
            return prev.map((val, idx) => {
                if(idx === actIndex){
                    return {
                        ...val,
                        attempt: val.attempt + 1,
                    };
                }
                return val;
            })
        })
        if(attempt >= 3) return false;
        setSMEdetailsList((prev) => {
            return prev.map((val, idx) => {
                if(idx === actIndex){
                    return {
                        ...val,
                        phoneShow: false,
                        wait: true,
                    };
                }
                return val;
            })
        })
        smeIntervalIDObj[actIndex] = setInterval(() => {
            sestSmeTimerObj((prev)=>{
                if(prev[actIndex] === 1){
                    clearInterval(smeIntervalIDObj[actIndex]);
                    setSMEdetailsList((prev) => {
                        return prev.map((val, idx) => {
                            if(idx === actIndex){
                                return {
                                    ...val,
                                    phoneShow: true,
                                    wait: false,
                                }
                            }
                            return val;
                        })
                    })
                    return {
                        ...prev,
                        [actIndex]: 10
                    }
                }
               return {
                ...prev,
                [actIndex]: prev[actIndex] - 1
               }
            })
        }, 1000);
    }

    const handleSubmitClick = (idx=0) => {/* 1.11.8 Submit case assignment */
        let postData = {}
        postData.pyID = ticket.pyID;
        postData.INCid = ticket.INCid;
        postData.nextAssignmentID = ticket.assignmentID;
        postData.BacklogId = ticket.BacklogId;
        postData.OnCallSME = smeDetailsList[idx].OnCallSME;
        postData.WebexCallAnswered = !smeDetailsList[idx].webex;
        if (smeDetailsList[idx].webex) {
            postData.PhoneCallAnswered = smeDetailsList[idx].picked;
            postData.PhoneCallAttempts = smeDetailsList[idx].attempt;
        }
        postData.IsAlternateSME = (smeDetailsList[idx].newEngineer != "")
        postData.AvailableForSupport = !smeDetailsList[idx].unavailable;
        if (smeDetailsList[idx].newEngineer != "") {
            postData.AlternateSME = smeDetailsList[idx].newEngineer
        }
        toast("You have submitted the case. Please wait.");
        postOnCall(postData).then((data) => {
            history.push(`/coverage`);
            console.log(data)
        }).catch(err => console.log(err));
    }
    return <>
        <h6 className="noteGCS"><b>Important!</b> Please inform the client that the process of engaging the oncall engineer generally takes 15-30 minutes.. </h6> 
        <h6 className="noteGCS" style={{color: "#999999", fontSize: "14px"}}><b>Notes:</b> Kindly contact engineers based on priority, meaning, if the Primary Engineer is unavailable, proceed to reach out to the next prioritized engineers.</h6> 
        <hr/>
        <div className="row pb-4 mb-4 col-sm-12" style={{display: "flex"}}>
        {
            <>
                {smeDetails.IsActiveSMEAvailable == "true" && smeDetailsList.map((smedata, index) => {
                        return (
                            <div key={index+smedata.Email} className="col-sm-6 mb-4 pb-2" style={{order: smedata.SMEClass == "SEC" ? "2" : "1"}}>
                                <div className="userProfile smeProfile">
                                    <ul className="list-group list-group-flush text-left">
                                        <li className="list-group-item" style={{color: smedata.SMEClass == "SEC" ? "#F9CB55" : "#EC5A28"}}><b>{smedata.SMEClass == "SEC" ? "Secondary Engineer" : "Primary Engineer"}</b></li>
                                        <li className="list-group-item"><b>Engineer OnCall:</b> {smedata.DisplayName} </li>
                                        <li className="list-group-item"><b>Job Title:</b> {smedata.JobTitle}</li>
                                        
                                        {/* 1.11.7-1 Show webex initially  */}
                                        <li className="list-group-item"><b>Webex Call : </b> <a href={`webexteams://im?email=${smedata.Email}`}>{smedata.Email}</a></li>
                                        <li className="list-group-item">
                                            <b>Webex Call Received ? </b> 
                                            <a className="check" onClick={() => setOnClickActions({picked: true} , index)}> &nbsp;</a>
                                            <a className="cross" onClick={() => setOnClickActions({webex: true}, index)}> &nbsp;</a> 
                                        </li>
                                        {/* 1.11.7-4 If picked and available proceed  */}
                                        {/* 1.11.7-2 If webex not picked show phone number  */}
                                        {/* {check.length && check.map((index) => { */}
                                            {smedata.webex &&  <>
                                                {smedata.phoneShow  && <li className="list-group-item"><b>Phone Call : </b> <a href={`tel:${smedata.Phone}`}>{smedata.Phone}</a></li>}
                                                {smedata.phoneShow && 
                                                    <li className="list-group-item">
                                                        <b>Phone Call Received ? </b> 
                                                        <a className="check" onClick={() => setOnClickActions({picked: true} , index)}> &nbsp;</a>
                                                        <a className="cross" onClick={() => setOnClickActions({attempt: smedata.attempt} , index)}> &nbsp;</a> 
                                                    </li>
                                                }
                                                {smedata.wait  && <li className="list-group-item"><b>Phone Call : </b> Attempt {attemptMap[smedata.attempt]} contact in {smeTimerObj[index]} </li>}
                                                {/* 1.11.7-3 Wait for 10 seconds , 2 attempts  */}
                                            </>}
                                            {/* 1.11.7-4 If picked and available proceed  */}
                                            {smedata.picked && 
                                                <li className="list-group-item"><b>Available to take call? </b> 
                                                    <a className="check" onClick={() => setOnClickActions({confirmed: true }, index)} > &nbsp;</a>
                                                    <a className="cross" onClick={() => setOnClickActions({unavailable: true, confirmed: false}, index)}> &nbsp;</a> 
                                                </li>
                                            }
                                            {(smedata.unavailable || smedata.attempt >= 4 ) && <>
                                                {/* 1.11.7-5 Show Escalation Manager Details TODO */}
                                                <li className="list-group-item"><b>Escalation Manager 1 :</b> {smedata.ManagerInfo.DisplayName} </li>
                                                <li className="list-group-item"><b>Phone Call : </b> <a href={`tel:${smedata.ManagerInfo.Phone}`}>{smedata.ManagerInfo.Phone}</a></li>
                                                <li className="list-group-item"><b>Escalation Manager 2 :</b> {smedata.ManagerInfo.ManagerInfo.DisplayName} </li>
                                                <li className="list-group-item">
                                                    <b>Phone Call : </b> 
                                                    <a href={`tel:${smedata.ManagerInfo.ManagerInfo.Phone}`}>{smedata.ManagerInfo.ManagerInfo.Phone}</a>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Alliance Manager :</b> 
                                                    {smedata.ManagerInfo.ManagerInfo.ManagerInfo && smedata.ManagerInfo.ManagerInfo.ManagerInfo.DisplayName}  
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Phone Call : </b> 
                                                    <a href={smedata.ManagerInfo.ManagerInfo.ManagerInfo && `tel:${smedata.ManagerInfo.ManagerInfo.ManagerInfo.Phone}`}>{smedata.ManagerInfo.ManagerInfo.ManagerInfo && smedata.ManagerInfo.ManagerInfo.ManagerInfo.Phone} </a>
                                                </li>
                                                <li className="list-group-item"><b>Alternate SME Assigned:</b> <input value={smedata.newEngineer} onChange={(e) => setOnClickActions({newEngineer: e.target.value}, index)} placeholder="Pega Email ID" /> </li>
                                                {smedata.newEngineer != "" && 
                                                    <li className="list-group-item">
                                                        <b>Available to take call? </b> 
                                                        <a className="check" onClick={() => setOnClickActions({confirmed: true}, index)} > &nbsp;</a>
                                                        <a className="cross" onClick={() => setOnClickActions({unavailable: true, confirmed: false}, index)}> &nbsp;</a> 
                                                    </li>
                                                }
                                            </>}
                                        {/* })} */}
                                        
                                    </ul>
                                </div>
                                {smedata.confirmed &&
                                    <div className="pb-2 mt-2">
                                        <button
                                            className="btn btn-primary" onClick={e => handleSubmitClick(index)}
                                        >
                                            Submit
                                        {/* 1.11.9 Submit case assignment */}
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                {smeDetails.IsActiveTeamAvailable == "true" &&
                    <div className="col-sm-6">
                        <div className="userProfile smeProfile">
                        There are teams in offices that are open at this time. Please contact the manager over webex or email.

                            <div className="card" style={{ margin: 2 }}>
                                <div className="card-header">{smeDetails.BacklogId}</div>
                                {smeDetails.TeamList.map((team, index) => {
                                    return (<div key={index+team.TeamName} className="card-body" style={{ padding: 0 }}>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div>{team.TeamName}</div> 
                                                <div>Manager : <a href={`webexteams://im?email=${team.ManagerEmail}`} >{team.ManagerEmail}</a></div>
                                                <div>Location : {team.Location}</div>
                                            </li>
                                        </ul>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                }

                {smeDetails.IsActiveTeamAvailable == "false" && smeDetails.IsActiveSMEAvailable == "false" &&
                    <div className="col-sm-6">
                        <div className="userProfile smeProfile">
                            Unfortunately there is no oncall coverage or open office at the current time. Please contact the Roster manager(s) below for help.

                            <div className="card" style={{ margin: 2 }}>
                                <div className="card-header">{smeDetails.BacklogId}</div>
                                {smeDetails.EscalationManagerList &&  smeDetails.EscalationManagerList.map((manager, index) => {
                                    return (<div key={index+manager.Email} className="card-body" style={{ padding: 0 }}>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div>{manager.DisplayName}</div> 
                                                <div>Email : {manager.Email}</div>
                                                <div>Phone : {manager.Phone}</div>
                                            </li>
                                        </ul>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                }
                
                <div className="row pb-5"></div>
                <ToastContainer position="bottom-left"/>
            </>
                
        }
        </div>
    </>
}