export const FAQ = {
  Nominated: {
    title: "What should I know if I’m nominated, but not yet On-Call?",
    questions: [
      {
        question: "Where can I find the On-Call schedule and my shifts history?",
        answer: "The On-Call master schedule and all the On-Call rotations are stored in the Engineering On-Call application (https://oncall.pega.com/ ).",
        note: "Note: You are On-Call only if your name is present on the master schedule. Please make sure the data is correct BEFORE you start your shift. You won’t get compensation for shifts not present on the master schedule."
      },
      {
        question: "Who manages the schedule?",
        answer: "Backlog Representatives (aka Roster Managers) maintains the master schedule based on information received from backlog owners and managers. Managers work with nominated participants to provide a schedule that takes into account planned time off, equal distribution of shifts, and holidays coverage among single backlog."
      },
      {
        question: "How early will you notify me about shift assignments?",
        answer: "You will be getting the email notification as soon as your name is entered on the shift. There will be a reminder notification sent at appropriate time before the shift. It’s up to you, backlog owners, managers, and other nominated participants how distant On-Call shifts you want to plan. The only requirement is to keep the continuity of On-Call coverage, for which backlog owners and managers are responsible. As a result, you will be able to plan your time off accordingly.",
        updated: true
      },
      {
        question: "Can I choose some particular date for an On-Call?",
        answer: "You can exchange your shift with another nominated engineer in your backlog area as long as you come to an agreement and you communicate this change to the Backlog Representative."
      },
      {
        question: "What if I have to cancel my shift?",
        answer: "If your plans changed, contact your manager or find an alternate nominee in your backlog and ask for covering your shift. In case of emergency (last-minute changes), contact the Program Manager to find a replacement."
      },
      /*{
        question: "How long is the On-Call shift?",
        answer: "In the US and India the shift may be as long as a week, consisting of evenings and weekends. In the Netherlands and Poland, there are separate shifts covering weekdays and each of the weekend days. Please follow the master schedule stored in the Engineering On-Call application for specific shift duration details."
      },*/
      {
        question: "What is the compensation amount for an On-Call shift?",
        answer: "The compensation amount for a shift is a subject to local customs and regulations, which may vary by region. Upon your nomination, you will receive email communication with compensation details for your location."
      }
    ]
  },
  OnCall: {
    title: "I am On-Call now. What are the expectations?",
    questions: [
      {
        question: "What hours do I need to be available while being On-Call?",
        answer: "During the week, you are asked to be On-Call only when there is no open office with teams representing your backlog. Because of that, the duration of the weekday On-Call differs between backlogs. Working hours for backlogs are marked on the master schedule, along with team breakdown for the backlog. For example, the Cloud backlog is represented by teams in Hyderabad, Bangalore, Krakow, and Cambridge between 5 am and 9 pm UTC, so the weekday On-Call is limited to 9 pm - 5 am UTC only. When it comes to a weekend day, you are asked to be On-Call for 24 hours. You can decide to split this rotation with other On-Call Engineer into two 12-hour shifts. However, in this case, each of you will receive 50% of the standard for your location weekend day On-Call allowance.",
        updated: true
      },
      {
        question: "What is my responsibility during On-Call shift?",
        answer: "The most critical responsibility is to be available by phone, for which you are accountable. It means that we track the response rate to the escalations. If you decided to be On-Call, make sure you can pick up the phone or call back within minutes. A sense of urgency is essential when working on an escalated issue! You are asked to support GCS in removing impediments within the knowledge and experience you have. If the problem being worked is outside your expertise and you won’t be able to provide any technical advice, please engage Escalation Leadership (GCSSELT@pega.com) and your manager to ensure the issue is transitioned and worked by an appropriate engineer. Also, we ask you to support the transition until the desired engineers are up to speed with the escalation. Remember we are one team with a common goal - provide the best possible support for our clients. Note also that Release Engineering is available 24/7 when a hotfix is required.",
        updated: true
      },
      {
        question: "Who can assign work to me during On-Call?",
        answer: "The work you perform during On-Call can be assigned by Escalation Leadership approval only. During this timeframe, you are not expected to do any other types of work than escalations. By escalation, we understand any work approved by the EL and not limited to “Sev 1” items only. The EL understands the purpose of the On-Call program to be used in emergency situations only. By default, we assume all the engagements approved by them have a solid business justification, regardless of severity level present in the Pega Support application.",
        updated: true
      },
      {
        question: "Where do I need to be during the On-Call shift?",
        answer: "We don’t ask you to stay at the office during the On-Call. You can be anywhere you want - as long as you always have your phone with you and have access to your Pega laptop and to broadband internet."
      },
      {
        question: "What communication channels should I follow?",
        answer: "You will be reached first on Webex teams.If unavailable the phone number you provided will be used as a secondary channel of communication. Subsequent to the initial communication, you may need to monitor email or Webex/Skype."
      },
      {
        question: "What is the desired reaction time and SLA?",
        answer: "After contact by Escalation Leadership, please start working on the issue within an hour. There is no SLA for the work - it needs to be done as soon as possible."
      },
      {
        question: "Will I get a company phone or get reimbursed for using my private phone?",
        answer: "Please use your private phone for receiving calls from the Escalation Leadership only. After that, use Cisco IP Communicator installed on your Pega laptop as well as Cisco Webex software. In case you received any substantial bill related to answering an international call, please contact the Program Manager. All claims will be analyzed individually for potential phone reimbursements.",
        updated: true
      },
      {
        question: "Do I have the necessary access to resources and customer data?",
        answer: "GCS is responsible for collecting necessary data from customers. If something is missing - reach out to them first."
      },
      /*{
        question: "What’s the Hot Fix process during an On-Call rotation?",
        answer: "TIP: Right-click on the image and open it in a new window. Additional resources: https://mesh.pega.com/community/pega/squid/blog/2018/04/20/74-hot-fix-development-process-looks https://mesh.pega.com/docs/DOC-37042 https://pega.app.box.com/s/9r3h6z45wfwae57ov05ovdq2rk7s3ssf",
        image: "hotfix.png",
        updated: true
      }*/,
      {
        question: "What if I didn’t finish the work during the shift?",
        answer: "It may happen, that shift ends before you provide the final solution to the customer. Please refer to the master schedule document to identify the next person On-Call and transfer your knowledge about the issue to he/she along with all the necessary files and access data."
      },
      {
        question: "How to track the time spent on actual work?",
        answer: "A feedback survey email is sent to you which you are expected to fill up, you can add comments",
        updated: true
      },
      {
        question: "When I get paid for the On-Call?",
        answer: "All On-Call shifts that appear on the master schedule, are submitted to payroll once, after each month. In the US, you will get paid for On-Call in accordance to the Payroll Schedule. In other countries with a monthly compensation, the On-Call bonus will be added to the next-month’s payroll.",
        image: "compensation.png"
      }
    ]
  }
}
