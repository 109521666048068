import React, { useEffect, useState } from "react";

const ChooseEngineer = ({
  peopleInBacklog,
  onChunkChange,
  onClassChange,
  onEngineerChange,
  rotationClass,
  rotationChunk,
  userId,
}) => {
  return (
    <div className="text-left" style={{ fontSize: "12px" }}>
      <div className="selector-horizontal count-2">
        <span
          className={
            "selector-item" + (rotationClass === "PRIM" ? " active" : "")
          }
          onClick={() => onClassChange("PRIM")}
        >
          Primary
        </span>
        <span
          className={
            "selector-item" + (rotationClass === "SEC" ? " active" : "")
          }
          onClick={() => onClassChange("SEC")}
        >
          Secondary
        </span>
      </div>
      <div className="selector-horizontal count-3">
        <span
          className={
            "selector-item" + (rotationChunk === "1/1" ? " active" : "")
          }
          onClick={() => onChunkChange("1/1")}
          style={{ width: "40%" }}
        >
          Full Time(1/1)
        </span>
        <span
          className={
            "selector-item" + (rotationChunk === "1/2" ? " active" : "")
          }
          onClick={() => onChunkChange("1/2")}
          style={{ width: "30%" }}
        >
          (1/2)
        </span>
        <span
          className={
            "selector-item" + (rotationChunk === "2/2" ? " active" : "")
          }
          onClick={() => onChunkChange("2/2")}
          style={{ width: "30%" }}
        >
          (2/2)
        </span>
      </div>
      <div className="selector-vertical bottom-50">
        {Object.keys(peopleInBacklog).map((key, index) => {
          return (
            <span
              key={peopleInBacklog[key].ID}
              value={peopleInBacklog[key].ID}
              className={
                "selector-item" +
                (userId === key ? " active" : "") +
                (!peopleInBacklog[key].PhonePrimary ? " disabled" : "")
              }
              onClick={() => onEngineerChange(key)}
            >
              <b>
                {peopleInBacklog[key].DisplayName}{" "}
                {peopleInBacklog[key].LastName}
              </b>{" "}
              ({key}) <br />
              {peopleInBacklog[key].JobTitle} <br />
              Location: {peopleInBacklog[key].Location}
              {!peopleInBacklog[key].PhonePrimary && <br />}
              {!peopleInBacklog[key].PhonePrimary && (
                <span className="font-extra-bold text-orange left-10">
                  No phone number provided!
                </span>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseEngineer;
