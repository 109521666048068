import React from "react";
import { getLocalizedTimefromDate } from "../..//momentTools";
import { updateServiceTeamFlag } from "../../api";

const IconWithText = ({ icon, text }) => {
  return (
    <div className="row" style={{ padding: "3px 0px" }}>
      <div className="col-sm-12" style={{ textAlign: "left" }}>
        <i className="material-icons text-dark-grey">{icon}</i>
        {text}
      </div>
    </div>
  );
};

const BacklogCardItem = ({ item, timeZone, locations, activeUser }) => {
  const label = item.Name || item.UserDisplayName + " " + item.UserLastName;//1.8.1 label is teamname or engineer full name
  const id = item.UserID || item.ID; // 1.8.2 Engineer user id or team project id
  const location = item.UserLocationName || item.LocationName || ""; // 1.8.3 User location or team location
  const start = item.StartTime || "2000-01-01T" + item.WorkingHoursStart; // 1.8.4 start and end
  const end = item.EndTime || "2000-01-01T" + item.WorkingHoursEnd;
  const managerFullName = [item.ManagerFirstName, item.ManagerLastName].join(
    " "
  ); // 1.8.5 manager name if available
  const isEUTeam =
    item.locationId in locations &&
    locations[item.locationId].euWorkRegulations === 1;//1.8.6 isEUTeam if euWorkRegulations enabled on location
  const isAdmin = activeUser.role === "admin";
  const onServiceTeamChange = (e) => { //1.8.7 is a service team update.what is a service team?
    const isAServiceTeam = e.target.checked;
    updateServiceTeamFlag(item.ID, isAServiceTeam)
      .then((r) => {})
      .catch((e) => {});
  };
  return (
    <div className="list-group mx-2 backlog-card-item" key={item.ID}>
      <div className="list-group-item">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">
            {label} <small>({id})</small>
          </h5>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <small>
              <i className="material-icons float-left text-dark-grey">
                location_on
              </i>
              {location.split("-").pop().trim()}
            </small>
          </div>
          <div className="col-sm-6">
            <small>
              <i className="material-icons float-left text-dark-grey">
                access_time
              </i>
              {getLocalizedTimefromDate(start, timeZone, "HH:mm")} -{" "}
              {getLocalizedTimefromDate(end, timeZone, "HH:mm")}
            </small>
          </div>
        </div>
        {managerFullName != " " && (
          <IconWithText icon="people" text={managerFullName} />
        )}
        {item.ManagerPhonePrimary && (
          <IconWithText icon="phone" text={item.ManagerPhonePrimary} />
        )}
        {/* 1.8.8 show service team checkbox only if it is a EU team and a service team */}
        {isEUTeam && isAdmin && (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={item.isAServiceTeam}
              onChange={onServiceTeamChange}
            />
            <label className="form-check-label">Is this a service team?</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default BacklogCardItem;
