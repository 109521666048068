import React, {Component} from 'react';
import {Timezones} from './Timezones';

class TimezoneSelector extends Component {

  updateTimezonePreference(){
    var {setTimezone, getTimezone, ActiveUser} = this.props;
    var userTimezone = (!ActiveUser || !ActiveUser.timezone ? "Etc/UTC" : ActiveUser.timezone);

    setTimezone(userTimezone);
  }

  componentDidMount(){
    this.updateTimezonePreference();
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.ActiveUser !== this.props.ActiveUser) this.updateTimezonePreference();
  }

  render() {
    var {setTimezone, getTimezone, ActiveUser} = this.props;

    return (
      <div className="text-right">
        <span className="timezone-label">Preview Timezone:</span>
        <select className="form-control timezone-selector" value={getTimezone()} onChange={ (event) => setTimezone(event.target.value)}>
          {Timezones.map( timezone =>
            <option key={timezone.id} value={timezone.id}>
              {timezone.name}
            </option>
          )}
        </select>
      </div>
    );
  }
}

export default TimezoneSelector;
