import React, { useEffect, useState } from "react";
import {
  formatDate,
  getLocalizedTimefromDate,
  getTimezoneOffset,
} from "../../momentTools";
import { toast } from "react-toastify";

const ShowOnlyDate = ({
  selectedDate,
  readOnly,
  previewOnly,
  peopleInBacklog,
  getRotationDetails,
  timeZone,
  submittedRotations,
  onSubmittedRotationsUpdate,
  selectedRotationUser,
  updateSelectedRotations,
  updateShowOnlyDate,
  isAlreadySubmitted,
  isRotationEngIndiaOrUSComplaint,
  areRotationsUSCompliant,
  oncallTimes,
  selectedRotationBacklog,
  rotations,
  removedRotations
}) => {
  const [totalRotaionUsers, setTotalRotationUsers] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableAddSEBtn, setDisableAddSEBtn] = useState(false);
  const [selectedRotationEngDetails, setSelectedRotationEngDetails] = useState(
    {}
  );
  const selectedDateLocal = formatDate(selectedDate, "YYYY-MM-DD");

  useEffect(() => {
    const updateSubmittedRotations = Object.entries(
      {
        ...submittedRotations[selectedDateLocal],
      } || {}
    ).map(([key, value]) => {
      // let rotationUser = getRotationDetails(selectedDateLocal, value.UserID);
      let rotationEng = peopleInBacklog[key] || peopleInBacklog[value.UserID];
      if (rotationEng) {
        return {
          ...value,
          ...{
            Timezone: timeZone,
            UserFullName: rotationEng.FirstName + " " + rotationEng.LastName,
            UserLocation: rotationEng.Location.split("-")[0],
            ManagerID: rotationEng.ManagerID,
            // StartTime: value.newRotation
            //   ? value.StartTime
            //   : formatDate(value.StartTime, "YYYY-MM-DDTHH:mm"),
            // EndTime: value.newRotation
            //   ? value.EndTime
            //   : formatDate(value.EndTime, "YYYY-MM-DDTHH:mm"),
            validationError: false,
          },
        };
      }
      return value;
    });
    setTotalRotationUsers(updateSubmittedRotations);
  }, []);

  const addSelectedUser = () => {
    const ID = new Date().getTime();
    let rotationUser = getRotationDetails(
      selectedDateLocal,
      selectedRotationUser
    );
    setSelectedRotationEngDetails(rotationUser);
    const { indRotations, usRotations } =
      getSegregatedRotations(totalRotaionUsers);
    let currentRotations = {};
    let allowedHours = 14;
    if (String(rotationUser.UserLocation).toLocaleLowerCase() === "in") {
      currentRotations = indRotations;
    } else if (String(rotationUser.UserLocation).toLocaleLowerCase() === "us") {
      currentRotations = usRotations;
      allowedHours = 24;
    }
    const isRotationCompliant = isRotationEngIndiaOrUSComplaint(
      selectedDate,
      currentRotations,
      { ...selectedRotationUser, ...rotationUser },
      allowedHours
    );
    if (isRotationCompliant.flag) {
      setTotalRotationUsers((prev) => [
        ...prev,
        {
          ...rotationUser,
          Timezone: timeZone,
          newRotation: true,
          ID,
        },
      ]);
    }
    setIsDirty(true);
  };

  const updateRotationTime = (value, currentRotationUser, type) => {
    const ID = currentRotationUser.ID;
    const localValue = value + getTimezoneOffset(selectedDateLocal, timeZone);
    const utcValue = getLocalizedTimefromDate(
      localValue,
      "Etc/UTC",
      "YYYY-MM-DDTHH:mmZ"
    );
    const currentRotaionEngDetails = getRotationDetails(
      selectedDateLocal,
      currentRotationUser
    );
    setTotalRotationUsers((prev) => {
      return prev.map((val) => {
        if (val.ID === ID) {
          let validateEndAndStart =
            type === "EndTime"
              ? utcValue <= currentRotaionEngDetails.EndTime &&
                utcValue >= currentRotaionEngDetails.StartTime
              : utcValue >= currentRotaionEngDetails.StartTime &&
                utcValue <= currentRotaionEngDetails.EndTime;
          if (!validateEndAndStart) {
            toast.dismiss();
            toast.error("Start and End date should be within OnCall range!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setDisableSaveBtn(true);
            return {
              ...val,
              [type]: utcValue,
              validationError: true,
            };
          } else if (
            validateEndAndStart &&
            !(
              (type === "EndTime" &&
                new Date(utcValue) >= new Date(val.StartTime)) ||
              (type === "StartTime" &&
                new Date(utcValue) <= new Date(val.EndTime))
            )
          ) {
            toast.dismiss();
            toast.error("Start date should be greater than End date!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setDisableSaveBtn(true);
            return {
              ...val,
              [type]: utcValue,
              validationError: true,
            };
          }
          return {
            ...val,
            [type]: utcValue,
            validationError: false,
            timeZoneEdited: "Etc/UTC",
            newRotation: true,
          };
        }
        return val;
      });
    });
    toast.dismiss();
    setDisableSaveBtn(false);
    setDisableAddSEBtn(false);
    setIsDirty(true);
  };

  const validateOverlap = () => {
    let isStartDuplicate = false;
    let engineersTimes = totalRotaionUsers.reduce((prev, curr) => {
      prev.push({
        StartTime: curr.StartTime,
        EndTime: curr.EndTime,
        priority: curr.Class,
        UserID: curr.UserID,
        newRotation: curr.newRotation,
      });
      return prev;
    }, []);
    let timesArray = [];
    engineersTimes.forEach((val, idx, arr) => {
      const { StartTime, UserID, priority } = val;
      arr.forEach((someVal, someIdx, someArr) => {
        if (idx !== someIdx) {
          if (priority === someVal.priority || UserID === someVal.UserID) {
            if (
              new Date(StartTime) - new Date(someVal.StartTime) >= 0 &&
              new Date(StartTime) - new Date(someVal.EndTime) < 0
            ) {
              timesArray.push(val);
            }
          }
        }
      });
    });
    if (timesArray.length) {
      timesArray.map((timesArrVal) => {
        setTotalRotationUsers((prev) => {
          return prev.map((val) => {
            if (
              getLocalizedTimefromDate(
                val.StartTime,
                timeZone,
                "YYYY-MM-DDTHH:mm"
              ) === timesArrVal.StartTime &&
              val.UserID === timesArrVal.UserID
            ) {
              return {
                ...val,
                validationError: true,
              };
            }
            return val;
          });
        });
      });
      setDisableAddSEBtn(true);
      isStartDuplicate = true;
    }
    return isStartDuplicate;
  };

  const deleteEntry = (entryId, selectedDateLocal) => {
    setTotalRotationUsers((prev) => {
      const updatedRotations = prev.filter((val) => val.ID !== entryId);
      prev.forEach((val) => {
        if (val.ID === entryId) {
          isAlreadySubmitted(selectedDateLocal, val);
        }
      });
      let rotationsObjFromArr = updatedRotations.reduce((prev, curr) => {
        prev[curr.ID] = curr;
        return prev;
      }, {});
      onSubmittedRotationsUpdate({
        ...submittedRotations,
        [selectedDateLocal]: { ...rotationsObjFromArr },
      });
      return updatedRotations.map((mappingVal) => ({
        ...mappingVal,
      }));
    });
    setDisableSaveBtn(false);
    setDisableAddSEBtn(false);
    setIsDirty(true);
  };

  const getSegregatedRotations = (rtUsers = []) => {
    return rtUsers.reduce(
      (totalUpdatedRotations, curr, idx, arr) => {
        totalUpdatedRotations["rotationsObjFromArr"][curr.ID] = curr;
        if (curr.newRotation) {
          totalUpdatedRotations["allNewRotations"][curr.ID] = curr;
          if (String(curr.UserLocation).toLocaleLowerCase() === "in") {
            totalUpdatedRotations["indRotations"][curr.ID] = curr;
          }
          if (String(curr.UserLocation).toLocaleLowerCase() === "us") {
            totalUpdatedRotations["usRotations"][curr.ID] = curr;
          }
        }
        return totalUpdatedRotations;
      },
      {
        rotationsObjFromArr: {},
        allNewRotations: {},
        indRotations: {},
        usRotations: {},
      }
    );
  };

  const validateAndClose = () => {
    if (isDirty) {
      if (validateOverlap()) {
        toast.dismiss();
        toast.error("Engineers timelines should not overlap!", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      const {
        rotationsObjFromArr,
        indRotations,
        allNewRotations,
        usRotations,
      } = getSegregatedRotations(totalRotaionUsers);

      if (Object.keys(rotations).length !== 0 || Object.keys(removedRotations).length !== 0) {
        let complianceExempted = false;
        // COMPLIANCEEXEMPTION - for exceptional cases with special permission
        if (selectedRotationBacklog.Specialization == "COMPLIANCEEXEMPTION") {
          complianceExempted = true;
        }
        if (complianceExempted || areRotationsUSCompliant()) {
          updateShowOnlyDate(true);
        }
      }
        
      const isAllRotationsInValidTime = totalRotaionUsers.every(
        (val, idx, arr) => {
          let isIndiaCompliant = { flag: true };
          let isUsCompliant = { flag: true };
          if (String(val.UserLocation).toLocaleLowerCase() === "in") {
            isIndiaCompliant = isRotationEngIndiaOrUSComplaint(
              selectedDate,
              indRotations,
              val,
              14
            );
          }
          // ** we have separate function 'areRotationsUSCompliant' to call for US location ** 
          // if (String(val.UserLocation).toLocaleLowerCase() === "us") {
          //   isUsCompliant = isRotationEngIndiaOrUSComplaint(
          //     selectedDate,
          //     usRotations,
          //     val,
          //     24
          //   );
          // }
          return (
            isIndiaCompliant.flag && isUsCompliant.flag && !val.validationError
          );
        }
      );
      if (isAllRotationsInValidTime) {
        updateSelectedRotations(selectedDateLocal, allNewRotations);
        onSubmittedRotationsUpdate({
          ...submittedRotations,
          [selectedDateLocal]: { ...rotationsObjFromArr },
        });
        updateShowOnlyDate(true);
      }
      // else {
      //   toast.dismiss();
      //   toast.error(
      //     "Please correct the timelines/errors! \n Oncall engineers should be within oncall-range \n and should not overlap!",
      //     {
      //       position: toast.POSITION.TOP_CENTER,
      //     }
      //   );
      // }
      return;
    }
    updateShowOnlyDate(true);
  }

  return (
    <>
      {(!readOnly || !previewOnly) && (
        <div className="row body-header">
          <div className="col-sm-6 text-left mb-4">
            <button
              className="btn btn-sm btn-primary"
              type="button"
              onClick={addSelectedUser}
              disabled={disableAddSEBtn}
              style={
                disableAddSEBtn
                  ? { cursor: "not-allowed", opacity: 0.5 }
                  : { cursor: "pointer" }
              }
            >
              Add Selected Engineer
            </button>
          </div>
          <div
            className="col-sm-6 text-right mb-4"
          >
            <button
              className="btn btn-sm btn-warning"
              type="button"
              disabled={disableSaveBtn}
              onClick={validateAndClose}
            >
              {isDirty ? "Save and Close" : "Close"}
            </button>
          </div>
        </div>
      )}
      <table
        style={{ fontSize: "12px" }}
        className="rotation-table table table-sm table-striped table-hover table-responsive"
      >
        <caption>List of Rotation users</caption>
        <thead className="thead-dark">
          <tr className="th-sm">
            <th scope="col" width="15%" className="align-middle">
              User Name
            </th>
            <th scope="col" width="15%" className="align-middle">
              Start Date
            </th>
            <th scope="col" width="15%" className="align-middle">
              End Date
            </th>
            <th scope="col" width="10%" className="align-middle">
              Timezone
            </th>
            <th scope="col" width="10%" className="align-middle">
              Priority
            </th>
            <th scope="col" width="10%" className="align-middle">
              Manager ID
            </th>
            <th scope="col" width="10%" className="align-middle">
              User Location
            </th>
            {!readOnly && (
              <th scope="col" width="15%" className="align-middle">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {totalRotaionUsers.length
            ? totalRotaionUsers.map((rotationUser) => {
                return (
                  <tr
                    style={
                      rotationUser.validationError ? { color: "red" } : null
                    }
                    key={rotationUser.ID}
                  >
                    <td>{rotationUser.UserFullName}</td>
                    <td>
                      <input
                        style={
                          readOnly
                            ? { width: "155px", border: "none" }
                            : { width: "155px" }
                        }
                        type={"datetime-local"}
                        name="start-time"
                        onChange={(e) =>
                          updateRotationTime(
                            e.target.value,
                            rotationUser,
                            "StartTime"
                          )
                        }
                        autoSave="false"
                        autoComplete="false"
                        disabled={readOnly || !rotationUser.newRotation}
                        value={getLocalizedTimefromDate(
                          rotationUser.StartTime,
                          timeZone,
                          "YYYY-MM-DDTHH:mm"
                        )}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          readOnly
                            ? { width: "155px", border: "none" }
                            : { width: "155px" }
                        }
                        type={"datetime-local"}
                        name="end-time"
                        disabled={readOnly || !rotationUser.newRotation}
                        onChange={(e) =>
                          updateRotationTime(
                            e.target.value,
                            rotationUser,
                            "EndTime"
                          )
                        }
                        value={getLocalizedTimefromDate(
                          rotationUser.EndTime,
                          timeZone,
                          "YYYY-MM-DDTHH:mm"
                        )}
                      />
                    </td>
                    <td>{rotationUser.Timezone}</td>
                    <td
                      style={{
                        color:
                          rotationUser.Class == "SEC" ? "#F9CB55" : "#EC5A28",
                        fontWeight: "bold",
                      }}
                    >
                      {rotationUser.Class === "PRIM" ? "Primary" : "Secondary"}{" "}
                      {rotationUser.Chunk && " [" + rotationUser.Chunk + "]"}
                    </td>
                    <td>{rotationUser.ManagerID}</td>
                    <td>{rotationUser.UserLocation}</td>
                    {!readOnly && (
                      <td>
                        <button
                          onClick={() =>
                            deleteEntry(rotationUser.ID, selectedDateLocal)
                          }
                        >
                          Delete
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      <div className="footer text-right"></div>
    </>
  );
};

export default ShowOnlyDate;
