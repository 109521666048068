import moment from 'moment-timezone';

export const formatDate = ( date, format ) => {
  return moment(date).format(format);
}

export const getLocalizedTimefromDate = ( date, timeZone, format) => {
  return moment.utc(date).tz(timeZone).format(format);
}

export const getLocalizedTime = ( hour, timeZone ) => {
  return moment.utc(hour, "HH:mm:ss").tz(timeZone).format("HH:mm");
}

export const getLocalTime = ( timeZone ) => {
  return moment.tz(timeZone).format('HH:mm:ss');
}

export const getTimezoneOffset = (date, timeZone) => {
  return moment.tz(date, timeZone).format('Z');
}

export const getCurrrentUTCDate = (format) => {
  return moment.utc().format(format);
}

export const getNumberDayofWeek = (day) => {
  return moment(day).day();
}

export const getNextWeekday = (format) => {
  if (moment().weekday() < 5) {
    return moment().add(1, 'days').format(format);
  } else {
    return moment().endOf('isoweek').add(1, 'days').format(format)
  }
}
export const getDaysInMonth = (month) => {
  return moment(month).daysInMonth();
}

export const addMinutesToDate = (date, minutes, format) => {
  return moment(date).add(minutes, 'minutes').format(format);
}

export const addHoursToDate = (date, hours, format) => {
  return moment("2000-01-01T" + date).add(hours, 'hours').format(format);
}

export const addHoursToDateTime = (datetime, hours, format) => {
  return moment(datetime).add(hours, 'hours').format(format);
}

export const addDaysToDate = (date, days, format) => {
  return moment(date).add(days, 'days').format(format);
}

export const subtractDaysToDate = (date, days, format) => {
  return moment(date).subtract(days, 'days').format(format);
}

export const startOfMonth = (month, format) => {
  return moment(month).startOf('month').format(format);
}

export const endOfMonth = (month, format) => {
  return moment(month).endOf('month').format(format);
}

export const isBetweenDate = (currentDate, startDate, endDate) => {
  return moment(currentDate).isBetween(startDate, endDate, null, '()');
}

export const isBeforeDate = (endDate, startDate) => {
  return moment(endDate).isBefore(startDate);
}

export const getTimeDiff = (endTime, startTime, format) => {
  let timeDiff = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format(format);
  let hrs = Number(timeDiff.split(":")[0]);
  let mins = Number(timeDiff.split(":")[1]);
  mins = mins/60;
  timeDiff = hrs + mins;
  return  timeDiff;
}