import React, {Component} from 'react';
import MyModal from '../../../Modal';
import {deleteUser} from '../../../../api.js';

class DeleteUser extends Component {

  onDeleteUser = () => {
    deleteUser(this.props.user.ID)
      .then( res => {
        this.props.onSuccessDelete(res.message);
        this.props.handleToggle();
      })
  }

  render() {
    return(
      <MyModal
        visible={true}
        modalBackdropClicked={this.props.handleToggle}
        header={"Remove On-Call Engineer"}
        body={
          <div className="DeleteUser">
            <div className="col-sm-12">
              <div className="row">
                <div className="mx-auto text-left">
                  <div className="backlogInfo alert alert-danger text-left">Are you sure to remove <b>{this.props.user.FirstName} {this.props.user.LastName} ({this.props.user.ID})</b> from the Engineering On-Call app?</div>
                </div>
              </div>
            </div>
          </div>
        }
        btnPrimary={{
          text:"Remove",
          color:"btn-danger",
          onClick: this.onDeleteUser
        }}
        btnSecondary={{
          text:"Cancel",
          color:"btn-secondary",
          onClick: this.props.handleToggle
        }}
        />
    )
  }
}

export default DeleteUser;
