import React from "react";

const Loader = ({ showLoader = false }) => {
  if (showLoader) {
    return (
      <div
        style={{
          background: "rgba(0,0,0,0.4)",
          width: "100%",
          height: "100%",
          position: "fixed",
          margin: "auto",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <div
          className="spinner-border"
          style={{
            width: "3rem",
            height: "3rem",
            position: "absolute",
            margin: "auto",
            color: "#fff",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return null;
};

export default Loader;
