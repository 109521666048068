import React, {Component} from 'react';
import { Rates } from './config.js';
import CompensationTable from './CompensationTable';
import { ToastContainer, toast } from 'react-toastify';
import { getCompensationMonth, postCompensationMonth, getHolidays, getCompensationID } from '../../api';
import { startOfMonth, getDaysInMonth, endOfMonth, addDaysToDate} from '../../momentTools';

class Compensation extends Component {
  state = {
    Report: {
      dateStart: undefined,
      dateEnd: undefined,
      month: undefined,
      dates: []
    },
    holidays: {},
    compensation: {},
    rates: Rates,
    compensation: {},
    isTableLarge: false,
    isShowButton: true,
  }

  componentDidMount() {
    if (this.props.match.params.month.includes('-')) {
      let Report = {...this.state.Report};
      let month = this.props.match.params.month;

      Report.month = month;
      Report.dateStart = startOfMonth(month, 'YYYY-MM-DD');
      Report.dateEnd = endOfMonth(month, 'YYYY-MM-DD');
      Report.dates = new Array(getDaysInMonth(month)).fill(null).map((key, index) => addDaysToDate(Report.dateStart, index, 'YYYY-MM-DD'));

      this.setState({Report})

      getCompensationMonth(month).then(compensation => {
        this.setState({compensation: compensation})
      })

      getHolidays(month).then(holidays => {
        this.setState({ holidays: holidays })
      })

      this.setState({Report})

    } else {
      const fileName = this.props.match.params.month;

      getCompensationID(fileName).then(res =>
        this.setState({
          Report: res,
          isShowButton: false,
        })
      );
    }

    document.onkeyup = (e) => {
      if (e.altKey && e.which === 84) {
        e.preventDefault();
        this.setState({isTableLarge: !this.state.isTableLarge})
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.month !== prevProps.month) {
      this.componentDidMount();
    }

  }

  onReportSubmit = () => {
    postCompensationMonth(JSON.stringify(this.state.Report))
      .then(res => {
        this.props.history.push({
          pathname: '/reports',
          state: {message: res.message}
        });
      })
      .catch(error => {
        toast.error(error.message, {position: toast.POSITION.TOP_RIGHT})
      })
  }

  render() {
    return(
      <div className="Compensation">
        <div className="row">
          <div className="col-sm-12">
            <h1>{this.state.isShowButton ? 'Create' : 'Preview'} Report</h1>
            <h4 className="pt-5">From <b>{this.state.Report.dateStart}</b> to <b>{this.state.Report.dateEnd}</b></h4>
            <h6><i>(Alt + T) for large preview</i></h6>
          </div>
        </div>
        <div className="row pt-5">
          <ToastContainer/>
          <CompensationTable
            showLarge={this.state.isTableLarge}
            Report={this.state.Report}
            compensation={this.state.compensation}
            dates={this.state.dates}
            holidays={this.state.holidays}
            rates={this.state.rates}/>
        </div>
        <div className="row">
          <div className="col-sm-12">
            {this.state.isShowButton && <button type="button" className="btn btn-primary" onClick={this.onReportSubmit}>Submit</button>}
          </div>
        </div>
      </div>
    )
  }

}

export default Compensation;
